<template>
  <section class="style-configurator scroll-content">
    <h3 class="md-display-1">{{ $translate("style_configurator.title") }}:</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("style_configurator.message") }}</div>
    </section_description_preview>

    <div
      v-for="[key, val] in colors"
      :key="`style-${key}`"
      class="style-configurator__color"
    >
      <div class="md-title">{{ $translate(`style_configurator.style_translations.${key}.title`) }}:</div>
      <span :style="{ backgroundColor: val }" class="md-elevation-3"/>
      <md-button
        class="md-primary"
        v-if="can_user_manage_configuration"
        @click="changing_style(key)"
      >{{ $translate("style_configurator.change_color") }}</md-button>
      <p class="md-body-1">{{ $translate(`style_configurator.style_translations.${key}.message`) }}</p>
    </div>

    <div class="style-configurator__palettes">
      <p class="md-headline">{{ $translate("style_configurator.palettes_title") }}</p>
      <p class="md-body-1">{{ $translate("style_configurator.palettes_message") }}</p>

      <div
        v-for="(pallete, key) in palettes"
        :key="key"
        data-cy="palletes"
        @click="select_palette(pallete)"
        class="style-configurator__palette"
      >
        <p data-cy="color-palette-name">{{ replace_underscores_for_spaces(key) }}:</p>
        <span
          v-for="[sub_key, value] in Object.entries(pallete)"
          v-show="!sub_key.includes('_RGB')"
          :key="`${key}-${sub_key}`"
          :style="{ backgroundColor: value }"
        />
      </div>
    </div>

    <md-dialog :md-active.sync="show_modal" class="style-configurator__modal">
      <md-dialog-title>{{ $translate("style_configurator.select_color") }}:</md-dialog-title>

      <chrome_color_picker v-model="selected_color"/>

      <md-dialog-actions>
        <md-button @click="reset_colors">{{ $translate("cancel") }}</md-button>
        <md-button class="md-raised md-primary" @click="update_style(true)">{{ $translate("save") }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { Chrome } from "vue-color"
import { ADMIN, CONFIGURATION_STORE, default_color_object, updatable_styles } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import { UPDATE_PROJECT_STYLE } from "../../../stores/Admin/configuration/constants"
import project_styles from "../../../../../data/project_styles"
import un_bind from "../../../../Shared/methods/un_bind"
import palettes from "../../../constants/palettes"
import replace_underscores_for_spaces from "../../../../Shared/methods/replace_underscores_for_spaces"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview,
    chrome_color_picker: Chrome
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show_modal: false,
      selected_color: un_bind(default_color_object),
      keys_needing_RGB: ["background_heavy", "body_color"],
      selected_style_key: "",
      palettes
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["project_styles"]),
    colors() {
      return Object
        .entries((this.project_styles || project_styles).colors)
        .filter(([style_key]) => updatable_styles.includes(style_key))
    }
  },
  watch: {
    "project_styles.colors"(new_val) {
      Object.entries(new_val).forEach(([key, val]) => {
        document.documentElement.style.removeProperty(`--${key}`);
        document.documentElement.style.setProperty(`--${key}`, val)
      })
    }
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_project_style: UPDATE_PROJECT_STYLE
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED
    }),
    replace_underscores_for_spaces,
    select_palette(palette) {
      Object.entries(palette).forEach(([key, val]) => {
        this.update_project_style([key, val, true])
        document.documentElement.style.setProperty(`--${key}`, val)
      })

      this.configuration_data_updated({ types_updated: ["project_styles"] })
    },
    changing_style(style_key) {
      this.selected_style_key = style_key
      this.show_modal = true
      this.selected_color = { hex: (this.project_styles || project_styles).colors[style_key] }
    },
    reset_colors() {
      this.selected_color = un_bind(default_color_object)
      this.show_modal = false
    },
    update_style(is_color) {
      this.update_project_style([
        this.selected_style_key,
        this.selected_color.hex,
        is_color
      ])

      document.documentElement.style.setProperty(`--${this.selected_style_key}`, this.selected_color.hex)

      if (this.keys_needing_RGB.includes(this.selected_style_key)) this.update_project_style([
        `${this.selected_style_key}_RGB`,
        `${this.selected_color.rgba.r}, ${this.selected_color.rgba.g}, ${this.selected_color.rgba.b}`,
        is_color
      ])
      this.configuration_data_updated({ types_updated: ["project_styles"] })
      this.reset_colors()
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .style-configurator {
    &__modal {
      padding: 0 $default-size;

      .md-dialog-title {
        padding: $default-size 0 0;
      }

      .vc-chrome {
        width: 400px;
        margin-bottom: $default-size;

        @media (max-width: $tablet--small) {
          width: calc(100vw - #{$double-default-size});
          height: auto;
        }
      }

      .vc-chrome-alpha-wrap {
        display: none;
      }
    }

    &__palette {
      margin-bottom: $default-size;
      cursor: pointer;

      @media (max-width: $mobile--large) {
        margin-bottom: $half-default-size;
      }

      p {
        text-transform: capitalize;
        font-weight: bold;
        margin: 0 0 $half-default-size;
      }

      &:hover {
        span {
          filter: drop-shadow(0 0 10px rgba($blue, 0.5));
        }
      }

      span {
        display: inline-block;
        margin-right: $half-default-size;
        width: $double-default-size;
        height: $double-default-size;
        border: 1px solid $material-grey--darkest;
        border-radius: 50%;

        &:nth-child(2) {
          margin-left: $half-default-size;
        }

        @media (max-width: $mobile--large) {
          width: $icon-size--small;
          height: $icon-size--small;

          &:nth-child(2) {
            margin-left: 0;
          }
        }
      }
    }

    &__color-preview {
      width: 100px;
      height: 100px;
      margin: $default-size auto;
    }

    &__color {
      margin-bottom: $default-size;
      padding: $half-default-size $default-size;
      border-left: 1px solid $blue;

      @media (max-width: $mobile--large) {
        margin-bottom: $half-default-size;
        padding: 0 0 0 $half-default-size;
      }

      span, button, div {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        width: 50px;
        height: 50px;
        margin-left: $default-size;
        border-radius: 100%;

        @media (max-width: $mobile--large) {
          width: $icon-size--small;
          height: $icon-size--small;
          margin-left: 0;
        }
      }

      div {
        @media (max-width: $mobile--large) {
          display: block;
        }
      }
      
      p {
        @media (max-width: $mobile--large) {
          margin: $half-default-size 0 0;
        }
      }
    }
  }
</style>
