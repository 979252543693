<template>
  <section class="shipping-options-config scroll-content">
    <h3 class="md-display-1">{{ $translate("shipping_options.title") }}:</h3>

    <section_description_preview>
      <p class="md-body-1">{{ $translate("shipping_options.message") }}</p>
    </section_description_preview>

    <!-- Lets hide Shopify for now, as no one will come from them at this point -->
    <!-- <p class="shipping-options-config__message"><shopify_icon/> <span>{{ $translate('shipping_options.shopify_message') }}</span></p> -->

    <language_switcher
      @change-language="language => selected_language = language"
      :allow_only_enabled="true"
      :show_all_languages="false"
      :bottom_border="true"
      :selected_language="selected_language"
    />

    <md-tabs class="md-transparent" :md-active-tab="active_tab" @md-changed="tab => active_tab = tab">
      <md-tab id="discounts" :md-label="$translate('shipping_options.tabs.shipping_discounts')">
        <p class="md-body-1">{{ $translate("shipping_options.tabs.shipping_discounts_message") }}</p>

        <div class="md-layout md-gutter">
          <div
            v-for="({ discount_percentage, translations }, ind) in shipping_discounts"
            :key="`shipping-options-config-${ind}`"
            class="md-layout-item md-size-25 md-small-size-50 md-xsmall-size-100"
          >
            <div class="shipping-options-config__wrapper md-elevation-3 shipping-options-config__discounts">
              <md-field>
                <label>{{ $translate('shipping_options.inputs.minimum_basket_price') }}</label>
                <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
                <md-input
                  data-cy="minimum-basket-price"
                  :disabled="!can_user_manage_configuration"
                  :value="(translations[selected_language] || {}).minimum_basket_price || ''"
                  @change="event => update_discounts('translations', event.target.value, ind, 'minimum_basket_price')"
                  type="number"
                />
                <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
              </md-field>
              <md-field>
                <label>{{ $translate('shipping_options.inputs.discount_percentage') }}</label>
                <md-input
                  data-cy="discount-percentage"
                  :disabled="!can_user_manage_configuration"
                  :value="discount_percentage"
                  @change="event => update_discounts('discount_percentage', event.target.value, ind)"
                  min="1"
                  max="100"
                  type="number"
                />
                <span class="md-suffix">%</span>
              </md-field>
              <md-field>
                <label>{{ $translate('shipping_options.inputs.title') }}</label>
                <md-input
                  data-cy="percent-off-shipping"
                  :placeholder="`${discount_percentage}% off shipping`"
                  :disabled="!can_user_manage_configuration"
                  :value="(translations[selected_language] || {}).title || ''"
                  @change="event => update_discounts('translations', event.target.value, ind, 'title')"
                />
              </md-field>
              <md-button
                v-if="can_user_manage_configuration"
                class="md-icon-button md-raised md-accent"
                @click="delete_option(ind)"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </div>
          </div>

          <md-button
            data-cy="add-shipping-discount"
            v-if="can_user_manage_configuration"
            class="md-icon-button md-raised md-primary"
            @click="add_option"
          >
            <md-icon>add</md-icon>
        </md-button>
        </div>
      </md-tab>

      <md-tab id="methods" :md-label="$translate('shipping_options.tabs.custom_methods')">
        <!-- TODO enable once calculated shipping enabled -->
        <!-- <p class="md-body-1">{{ $translate("shipping_options.tabs.custom_methods_message") }}</p> -->
        <!-- <span>
          <md-tooltip md-direction="top">{{ $translate("shipping_options.tooltips.use_custom_methods") }}</md-tooltip>
          <md-checkbox v-if="can_user_manage_configuration" :disabled="true" v-model="use_custom_methods">
            {{ $translate("shipping_options.inputs.use_custom_methods") }}
          </md-checkbox>
        </span> -->

        <section class="md-layout md-gutter shipping-options-config__shipping-options">
          <div class="md-layout-item md-size-75 md-small-size-50 md-xsmall-size-100">
            <div
              v-for="(method, ind) in custom_shipping_methods"
              :key="`shipping-options-config-${ind}`"
              class="shipping-options-config__wrapper shipping-options-config__method md-elevation-3"
            >
              <div class="shipping-options-config__inputs">
                <md-field>
                  <label>{{ $translate("shipping_options.inputs.title") }}*</label>
                  <md-input
                    data-cy="shipping-method-title"
                    :disabled="!can_user_manage_configuration"
                    :value="(method.translations[selected_language] || {}).title || ''"
                    @change="event => update_custom_methods('title', event.target.value, ind)"
                  />
                </md-field>
                <md-field>
                  <label>{{ $translate("shipping_options.inputs.price") }}*</label>
                  <span class="md-prefix">{{ currency }}</span>
                  <md-input
                    data-cy="shipping-method-price"
                    :disabled="!can_user_manage_configuration"
                    :value="(method.translations[selected_language] || {}).price || ''"
                    @change="event => update_custom_methods('price', event.target.value, ind)"
                    type="number"
                  />
                </md-field>
                <md-field>
                  <label>{{ $translate("shipping_options.inputs.estimated_days") }}</label>
                  <md-input
                    data-cy="estimated-delivery-days"
                    :disabled="!can_user_manage_configuration"
                    :value="method.estimated_days"
                    type="number"
                    @change="event => update_custom_methods('estimated_days', event.target.value, ind)"
                  />
                </md-field>
                <!-- <md-field> TODO Determine if necessary
                  <label>{{ $translate('shipping_options.inputs.code') }}</label>
                  <md-input
                    :disabled="!can_user_manage_configuration"
                    :value="method.code"
                    @change="event => update_custom_methods('code', event.target.value, ind)"
                  />
                </md-field> -->

                <div class="shipping-options-config__inputs-description">
                  <div class="md-body-2">{{ $translate("shipping_options.allowed_countries_for_method") }}:</div>
                  <span class="md-body-1">{{ $translate("shipping_options.no_selected_country_message") }}</span>
                </div>

                <md-field>
                  <label for="country">{{ $translate("shipping_options.inputs.countries") }}</label>
                  <md-select
                    v-model="method.allowed_countries"
                    name="country"
                    id="country"
                    md-dense
                    multiple
                    @md-selected="selected => update_custom_methods('allowed_countries', selected, ind)"
                  >
                    <md-optgroup
                      v-for="{ countries, continent_name } in grouped_countries()"
                      :key="`continent-${continent_name}`"
                      :label="continent_name"
                    >
                      <md-option
                        v-for="{ code, name } in countries"
                        :key="`country-${code}`"
                        :value="code"
                      >{{ name }}</md-option>
                    </md-optgroup>
                  </md-select>
                </md-field>
                <md-checkbox
                  :value="!method.enable_on_pickup_payment"
                  @change="_ => update_custom_methods('enable_on_pickup_payment', !method.enable_on_pickup_payment, ind)"
                  class="md-primary"
                >
                  <md-tooltip md-direction="top">{{ $translate("shipping_options.tooltips.enables_on_pickup_payment") }}</md-tooltip>
                  {{ $translate("shipping_options.inputs.enables_on_pickup_payment") }}
                </md-checkbox>
              </div>
              <md-button
                v-if="can_user_manage_configuration"
                class="md-icon-button md-raised md-accent"
                @click="delete_option(ind)"
              >
                <md-icon>delete</md-icon>
              </md-button>
            </div>

            <md-button
              data-cy="add-custom-shipping-method"
              v-if="can_user_manage_configuration"
              class="md-icon-button md-raised md-primary"
              @click="add_option"
            >
              <md-icon>add</md-icon>
            </md-button>
          </div>

          <div class="md-layout-item md-size-25 md-small-size-50 md-xsmall-size-100">
            <div class="md-title">{{ $translate("shipping_options.other_shipping_methods") }}:</div>
            <br>

            <div class="shipping-options-config__other-options">
              <md-card class="shipping-options-config__other-option">
                <md-card-header class="shipping-options-config__other-option-header">
                  <div>
                    <img src="/static/icons/zasilkovna-logo.png" alt="zasilkovna">
                    <md-checkbox v-model="zasilkovna_enabled" :disabled="!can_user_manage_configuration || !can_zasilkovna_be_enabled">
                      {{ $translate("shipping_options.inputs.enable_zasilkovna") }}
                    </md-checkbox>
                  </div>

                  <md-card-expand-trigger>
                    <md-button class="md-icon-button">
                      <md-icon>keyboard_arrow_down</md-icon>
                    </md-button>
                  </md-card-expand-trigger>
                </md-card-header>

                <md-card-expand>
                  <md-card-expand-content>
                    <md-card-content>
                      <div class="md-body-1" v-html="$translate('shipping_options.zasilkovna_helper', { language: user_language === 'cz' ? 'cs' : 'en' })" />
                      <hr>
                      <md-field>
                        <label>{{ $translate("shipping_options.inputs.api_key") }}*</label>
                        <md-input
                          :disabled="!can_user_manage_configuration"
                          :value="zasilkovna_values.api_key"
                          @change="event => update_other_option('zasilkovna', 'api_key', event.target.value)"
                        />
                      </md-field>
                      <md-field>
                        <label>{{ $translate("shipping_options.inputs.api_password") }}*</label>
                        <md-input
                          :disabled="!can_user_manage_configuration"
                          :value="zasilkovna_values.api_password"
                          @change="event => update_other_option('zasilkovna', 'api_password', event.target.value)"
                        />
                      </md-field>
                      <md-field>
                        <label>{{ $translate("shipping_options.inputs.price") }}*</label>
                        <span class="md-prefix">{{ currency }}</span>
                        <md-input
                          :disabled="!can_user_manage_configuration"
                          :value="zasilkovna_price"
                          @change="event => update_other_option(
                            'zasilkovna',
                            'translations',
                            {
                              ...zasilkovna_values.translations,
                              [selected_language]: { price: event.target.value }
                            }
                          )"
                        />
                      </md-field>
                    </md-card-content>
                  </md-card-expand-content>
                </md-card-expand>
              </md-card>
            </div>
          </div>
        </section>
      </md-tab>

      <md-tab id="settings" :md-label="$translate('shipping_options.tabs.settings')">
        <p class="md-body-1">{{ $translate("shipping_options.tabs.settings_message") }}</p>

        <div class="md-layout md-gutter shipping-options-config__countries">
          <div class="md-layout-item md-size-25 md-medium-size-50 md-small-size-100">
            <p class="md-title">{{ $translate("shipping_options.allowed_countries") }}</p>
            <p class="md-body-1">{{ $translate("shipping_options.no_selected_country_message") }}</p>
            <md-field>
              <label for="country">{{ $translate("shipping_options.inputs.countries") }}</label>
              <md-select v-model="allowed_countries" name="country" id="country" md-dense multiple>
                <md-optgroup
                  v-for="{ countries, continent_name } in grouped_countries()"
                  :key="`continent-${continent_name}`"
                  :label="continent_name"
                >
                  <md-option
                    v-for="{ code, name } in countries"
                    :key="`country-${code}`"
                    :value="code"
                  >{{ name }}</md-option>
                </md-optgroup>
              </md-select>
            </md-field>
            <md-checkbox
              v-for="({ continent_name }, continent_key) in grouped_countries()"
              :key="`continent-${continent_key}`"
              :value="!all_countries_selected(continent_key)"
              :disabled="!can_user_manage_configuration"
              @change="can_user_manage_configuration && toggle_continent_countries(continent_key)"
            >
              {{ continent_name }}
            </md-checkbox>
          </div>
          <div class="md-layout-item md-size-25 md-medium-size-50 md-small-size-100">
            <p class="md-title">{{ $translate("shipping_options.maximum_allowed_shipping_discount") }}</p>
            <md-field>
              <label>{{ $translate("shipping_options.inputs.maximum_shipping_discount") }}</label>
              <span v-if="is_currency_symbol(currency)" class="md-prefix">{{ currency }}</span>
              <md-input :disabled="!can_user_manage_configuration" v-model="maximum_shipping_discount" />
              <span v-if="!is_currency_symbol(currency)" class="md-suffix">{{ currency }}</span>
            </md-field>
          </div>
        </div>
      </md-tab>

      <md-tab id="address" :md-label="$translate('shipping_options.tabs.address')">
        <address_config :can_user_manage_configuration="can_user_manage_configuration"/>
        <div>
          <md-checkbox :disabled="!can_user_manage_configuration" v-model="show_in_contact_page">
            {{ $translate("shipping_options.inputs.show_in_contact_page") }}
          </md-checkbox>
        </div>
      </md-tab>
    </md-tabs>
  </section>
</template>

<script>
import { mapState, mapMutations, mapGetters } from "vuex"
import { CONFIGURATION_STORE, ADMIN, USER_STORE } from "../../../constants/others_constants"
import { UPDATE_LOADER, CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import {
  UPDATE_CUSTOM_SHIPPING_METHODS, UPDATE_ERRORS, UPDATE_OTHER_SHIPPING_OPTIONS, UPDATE_PROJECT_ADDRESS, UPDATE_SHIPPING_ALLOWED_COUNTRIES, UPDATE_SHIPPING_DISCOUNTS, UPDATE_SHIPPING_MAX_DISCOUNT
} from "../../../stores/Admin/configuration/constants"
import empty_shipping_method from "../../../constants/empty_objects/empty_shipping_method"
import empty_shipping_discount from "../../../constants/empty_objects/empty_shipping_discount"
import { EMPTY_SHIPPING_OPTIONS, MISSING_TRANSLATIONS_FOR_SHIPPING_OPTIONS } from "../../../constants/validation/error_types"
import language_switcher from "../language_switcher"
import un_bind from "../../../../Shared/methods/un_bind"
import countries from "../../../../../data/countries"
import is_currency_symbol from "../../../../Shared/methods/is_currency_symbol"
import address_config from "../address_config"
import { GET_USER_LANGUAGE } from "../../../stores/Admin/user/constants"
import { other_shipping_methods } from "../../../../Shared/constants/other"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    language_switcher,
    address_config,
    section_description_preview
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      enable_change_image: false,
      open_modal: false,
      first_load: true,
      selected_language: "",
      active_tab: "discounts",
      uploaded_image: {},
      allowed_countries: [],
      custom_shipping_methods: [],
      address_fields: {
        first: ["name", "email", "phone", "company", "street1"],
        second: ["street2", "city", "zip", "state", "businessID", "vatID"]
      },
      countries
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["shipping_options", "translations"]),
    ...mapGetters(USER_STORE, {
      user_language: GET_USER_LANGUAGE
    }),
    zasilkovna_values() {
      return this.shipping_options?.other_methods?.zasilkovna || {}
    },
    can_zasilkovna_be_enabled() {
      return this.shipping_options?.other_methods?.zasilkovna?.api_password
        && this.shipping_options?.other_methods?.zasilkovna?.api_key
    },
    zasilkovna_enabled: {
      get() {
        return Boolean(this.shipping_options?.other_methods?.zasilkovna?.enabled)
      },
      set(value) {
        this.update_other_option(
          other_shipping_methods.zasilkovna,
          "enabled",
          value
        )
      }
    },
    zasilkovna_price() {
      return this.shipping_options?.other_methods?.zasilkovna?.translations?.[this.selected_language]?.price || 0
    },
    project_country: {
      get() { return this.project_address.country },
      set(value) { this.update_address("country", value) }
    },
    show_in_contact_page: {
      get() { return this.project_address.show_in_contact_page },
      set(value) { this.update_address("show_in_contact_page", value) }
    },
    project_address() {
      return this.shipping_options.project_address || {}
    },
    currency() {
      return this.translations[this.selected_language].currency_code
    },
    shipping_methods() {
      return this.shipping_options.custom_methods || []
    },
    shipping_discounts() {
      return this.shipping_options.shipping_discounts || []
    },
    shipping_settings() {
      return this.shipping_options.settings || {}
    },
    maximum_shipping_discount: {
      get() { return this.shipping_settings.maximum_shipping_discount || 0 },
      set(maximum_shipping_discount) {
        this.update_max_discount(Number(maximum_shipping_discount))
        this.configuration_data_updated({ types_updated: ["shipping_options"] })
      }
    },
    can_user_remove_language() {
      return Object.keys((this[`shipping_${this.active_tab}`][0] || {}).translations || {}).length > 1
    },
    is_translation_in_use () {
      if (this.active_tab === "settings" || this.active_tab === "address") return false

      return !!((this[`shipping_${this.active_tab}`][0] || {}).translations || {})[this.selected_language]
    },
    correct_flag_image() {
      return `/static/icons/countries/${this.selected_language}.svg`
    },
    available_languages() {
      return Object.keys(this.translations)
    }
  },
  watch: {
    async shipping_methods(new_val) {
      this.custom_shipping_methods = new_val.map(method => ({ ...method, allowed_countries: method.allowed_countries || [] }))

      if (
        this.available_languages.some(language => new_val.some(({ translations }) => (
          !translations[language] || (translations[language] && (translations[language].price === undefined || translations[language].price === "" || !translations[language].title))
        )
      ))) this.update_errors([await MISSING_TRANSLATIONS_FOR_SHIPPING_OPTIONS()])
      else if (new_val.some(({ translations }) => !translations[this.selected_language])) this.update_errors([await EMPTY_SHIPPING_OPTIONS()])
      else this.update_errors([])

    },
    allowed_countries(selected) {
      if (!this.first_load) {
        this.update_allowed_countries(selected.map(country => countries.find(({ code }) => code === country)))
        this.configuration_data_updated({ types_updated: ["shipping_options"] })
      }
    }
  },
  beforeMount() {
    this.selected_language = Object.keys(this.translations)[0]
  },
  mounted() {
    this.custom_shipping_methods = this.shipping_methods.map(method => ({ ...method, allowed_countries: method.allowed_countries || [] }))
    this.allowed_countries = (this.shipping_settings.allowed_countries || []).map(({ code }) => code)
    this.$nextTick(() => this.first_load = false)
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_shipping_methods: UPDATE_CUSTOM_SHIPPING_METHODS,
      update_shipping_discounts: UPDATE_SHIPPING_DISCOUNTS,
      update_errors: UPDATE_ERRORS,
      update_allowed_countries: UPDATE_SHIPPING_ALLOWED_COUNTRIES,
      update_max_discount: UPDATE_SHIPPING_MAX_DISCOUNT,
      update_project_address: UPDATE_PROJECT_ADDRESS,
      update_other_shipping_options: UPDATE_OTHER_SHIPPING_OPTIONS
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
      update_loader: UPDATE_LOADER
    }),
    is_currency_symbol,
    update_other_option(type, key, value) {
      if (!value) this.update_other_shipping_options([type, {
        ...this.shipping_options?.other_methods?.[type],
        enabled: false
      }])

      this.update_other_shipping_options([type, {
        ...this.shipping_options?.other_methods?.[type],
        [key]: value
      }])
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    },
    update_address(key, value) {
      this.update_project_address([key, value])
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    },
    grouped_countries() {
      return countries.reduce((grouped, country) => ({
        ...grouped,
        [country.continent_code]: {
          countries: [...((grouped[country.continent_code] || {}).countries || []), country],
          continent_name: country.continent_name
        }
      }), {})
    },
    all_countries_selected(selected_continent_code) {
      return this.grouped_countries()[selected_continent_code].countries.every(country => this.allowed_countries
        .map(country => countries.find(({ code }) => code === country))
        .filter(({ continent_code }) => continent_code === selected_continent_code)
        .find(({ code }) => code === country.code)
      )
    },
    /**
     * 
     */
    toggle_continent_countries(continent_code) {
      const has_continent_countries = this.all_countries_selected(continent_code)
      const filtered_out_continent_countries = this.allowed_countries.filter(
        code => !this.grouped_countries()[continent_code].countries.some(country => code === country.code)
      ).map(country => countries.find(({ code }) => code === country))
      const new_allowed_countries = has_continent_countries ?
        filtered_out_continent_countries : [...filtered_out_continent_countries, ...this.grouped_countries()[continent_code].countries]

      this.allowed_countries = new_allowed_countries.map(({ code }) => code)
    },
    /**
     * 
     */
    remove_selected_translation() {
      this[`update_${this.active_tab}`]("translations")
    },
    /**
     * 
     */
    add_option() {
      this[`update_shipping_${this.active_tab}`]([
        ...this[`shipping_${this.active_tab}`],
        un_bind((this.active_tab === 'methods' ? empty_shipping_method : empty_shipping_discount)(this.available_languages))
      ])
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    },
    /**
     * 
     */
    update_custom_methods(key, value, index) {
      this.update_shipping_methods(this.shipping_methods.map((option, ind) => {
        if (ind === index || index === undefined) {
          if (["price", "title"].includes(key)) {
            if (!option.translations) option.translations = {}
            if (!option.translations[this.selected_language]) option.translations[this.selected_language] = {}
            option.translations[this.selected_language][key] = value
          }
          else option[key] = value
        }

        return option
      }))
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    },
    /**
     * 
     */
    update_discounts(key, value, index, sub_key) {
      this.update_shipping_discounts(this.shipping_discounts.map((option, ind) => {
        if (ind === index || index === undefined) {
          if (key === "translations") {
            if (!option.translations[this.selected_language]) option.translations[this.selected_language] = {}
            if (sub_key === "minimum_basket_price") {
              value = (isNaN(value) || !value) ? 0 : value

              if (value < 0) value = 0

              option.minimum_basket_price = value
            }
            option.translations[this.selected_language][sub_key] = value
          }
          else if (key === "discount_percentage") {
            value = (isNaN(value) || !value)  ? 0 : value

            if (value > 100) value = 100
            if (value < 0) value = 0

            option.discount_percentage = value
          }
          else option[key] = value
        }

        return option
      }))
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    },
    /**
     * 
     */
    delete_option(index) {
      this[`update_shipping_${this.active_tab}`](this[`shipping_${this.active_tab}`].filter((_, ind) => ind !== index))
      this.configuration_data_updated({ types_updated: ["shipping_options"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";
  @use "../../../../../styles/flag_change.scss";

  .shipping-options-config {
    &__method {
      display: inline-block;
      margin-right: $default-size;

      @media (max-width: $tablet--small) {
        display: block;
        margin-right: 0;
      }
    }

    &__countries .md-body-1 {
      padding: 5px $half-default-size;
      border-radius: 2px;
      background-color: $blue;
      color: $pure-white;
    }

    &__shipping-options {
      .md-layout-item:first-child {
        margin: $default-size 0;

        @media (max-width: $tablet--small) {
          text-align: center;
        }
      }
    }

    &__other-option {
      &-header {
        display: grid;
        grid-template-columns: 1fr 40px;
        align-items: end;

        .md-card-actions {
          width: 40px;
          height: 40px;
        }
      }

      .md-field:last-child {
        margin-bottom: 0;
      }

      img {
        display: block;
        width: 100px;
        height: 50px;
        object-fit: contain;
      }
    }

    .md-field {
      align-items: center;
    }
    .md-prefix {
      margin-right: $half-default-size;
    }

    &__message {
      margin: $default-size 0;

      svg {
        display: inline-block;
        width: 40px;
        vertical-align: middle;
      }
      span {
        display: inline-block;
        width: calc(100% - 55px);
        padding: $half-default-size;
        margin-left: $half-default-size;
        vertical-align: middle;
        background-color: $material-red;
        border-radius: 2px;
        color: $pure-white;
      }
    }

    &__discounts {
      .md-field label {
        font-size: $font-size--tiny;
      }
    }

    &__wrapper {
      padding: $default-size;

      @media (max-width: $tablet) {
        margin-bottom: $default-size;
      }

      .md-button {
        margin: auto $default-size;
      }
    }

    &__inputs {
      width: 300px;
      vertical-align: middle;

      &-description {
        text-align: left;

        @media (max-width: $mobile--large) {
          .md-body-2 { font-size: $font-size--small; line-height: normal; }
          .md-body-1 { font-size: $font-size--tiny; line-height: normal; }
        }
      }

      @media (max-width: $tablet--small) {
        width: 100%;
      }

      .md-field {
        display: inline-flex;
        width: auto;
        min-width: 300px;
        align-items: center;

        @media (max-width: $tablet--small) {
          min-width: 100%;
        }
      }
    }

    .md-tabs {
      max-width: $max-content-width;

      @media (max-width: $tablet--small) {
        overflow: auto;

        .md-tabs-navigation {
          overflow: auto;
          display: block !important;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
        }

        .md-content {
          height: auto !important;
        }
      }
    }
  }
</style>
