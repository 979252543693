<template>
  <section v-if="rest_config && project_styles" class="logo-brand-config scroll-content">
    <h3 class="md-display-1">{{ $translate("logo_and_brand_configurator.title") }}:</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("logo_and_brand_configurator.message") }}</div>
    </section_description_preview>

    <div class="logo-brand-config__section logo-brand-config__section--icon">
      <p class="md-title">{{ $translate("logo_and_brand_configurator.icon") }}</p>
      <p class="md-body-1">
        <b>{{ $translate("logo_and_brand_configurator.icon_format_message") }}</b>
      </p>
      <p class="md-body-1">
        {{ $translate("logo_and_brand_configurator.preview_color", { color_type: $translate("style_configurator.style_translations.background_heavy.title") }) }}
      </p>
      <img
        :src="get_icon_image"
        alt="icon"
        class="md-elevation-3"
        :style="{ backgroundColor: rest_config.icon ? project_styles.colors.background_heavy : 'transparent'  }"
      >
      <md-button
        v-if="can_user_manage_configuration"
        class="md-raised md-primary logo-brand-config__button"
        @click="uploading_image('icon')"
      >
        {{ $translate("upload_image") }}
      </md-button>
    </div>

    <br/>
    <hr>
    <br/>

    <div class="logo-brand-config__section">
      <p class="md-title">{{ $translate("logo_and_brand_configurator.brand_banner") }}</p>
      <p class="md-body-1">
        {{ $translate("logo_and_brand_configurator.preview_color", { color_type: $translate("style_configurator.style_translations.body_color.title") }) }}
      </p>
      <img
        :src="get_banner_image"
        alt="brand banner"
        class="md-elevation-3"
        :style="{ backgroundColor: rest_config.brand_banner ? project_styles.colors.body_color: 'transparent' }"
      >
      <md-button
        v-if="can_user_manage_configuration"
        class="md-raised md-primary logo-brand-config__button"
        @click="uploading_image('banner')"
      >
        {{ $translate("upload_image") }}
      </md-button>
    </div>

    <br/>
    <hr>
    <br/>

    <div class="logo-brand-config__section logo-brand-config__section--icon">
      <p class="md-title">{{ $translate("logo_and_brand_configurator.fav_icon") }}</p>
      <img
        :src="get_favicon_image"
        alt="brand banner"
        class="md-elevation-3"
      >
      <md-button
        v-if="can_user_manage_configuration"
        class="md-raised md-primary logo-brand-config__button"
        @click="uploading_image('favicon')"
      >
        {{ $translate("upload_image") }}
      </md-button>
    </div>

    <md-dialog :md-active.sync="open_image_modal" class="logo-brand-config__modal">
      <md-dialog-title>
        {{
          $translate(`logo_and_brand_configurator.${
            selecting_icon ? 
              "uploading_icon" : selecting_banner ? 
                "uploading_banner" : "uploading_favicon"}`
          )
        }}
      </md-dialog-title>

      <div v-if="open_image_modal" class="logo-brand-config__modal-content">
        <div v-if="uploaded_image">
          <img :src="uploaded_image.src">

          <md-button class="md-raised md-accent logo-brand-config__button" @click="uploaded_image = ''">
            {{ $translate("clear_image") }}
          </md-button>
          <md-button class="md-raised md-primary logo-brand-config__button" @click="save_image">
            {{ $translate("upload_image") }}
          </md-button>
        </div>
        <div v-else class="md-elevation-5 image-upload">
          <input
            type="file"
            id="image-upload"
            accept="image/*"
            @drop="event => add_image(event.target.files[0])"
            @input="event => add_image(event.target.files[0])"
            v-cloak
          >
          <label for="image-upload">
            <md-icon>cloud_upload</md-icon>
            {{ $translate("drag_and_drop_image") }}
          </label>
        </div>
      </div>
    </md-dialog>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { ADMIN, CONFIGURATION, CONFIGURATION_STORE } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED, UPDATE_IMAGES_FOR_DELETE } from "../../../stores/Admin/constants"
import { UPDATE_PROJECT_REST_CONFIG } from "../../../stores/Admin/configuration/constants"
import { default_image_src } from "../../../../Shared/constants/other"
import upload_image from "../../../methods/upload_image"
import { image_dimension_names } from "../../../../../data/other_constants"
import get_image_src from "../../../../Shared/methods/get_image_src"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_image_modal: false,
      selecting_icon: true,
      selecting_favicon: false,
      selecting_banner: false,
      uploaded_image: "",
      default_image_src
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["rest_config", "project_styles", "project_config"]),
    get_icon_image() {
      return get_image_src(this.rest_config.icon)
    },
    get_banner_image() {
      return get_image_src(this.rest_config.brand_banner)
    },
    get_favicon_image() {
      return get_image_src(this.rest_config.favicon)
    },
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_project_rest_config: UPDATE_PROJECT_REST_CONFIG
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
      image_delete_toggle: UPDATE_IMAGES_FOR_DELETE
    }),
    get_image_src,
    uploading_image(type) {
      this.open_image_modal = true
      this.selecting_icon = type === "icon"
      this.selecting_favicon = type === "favicon"
      this.selecting_banner = type === "banner"
    },
    add_image(value) { // TODO unify image upload into single component
      try {
        const reader = new FileReader()

        reader.addEventListener("load", () => {
          this.uploaded_image = {
            src: reader.result,
            file: value,
            altText: "image"
          }
        }, false)

        reader.readAsDataURL(value)
      } catch (error) {
        console.log(error)
      }
    },
    async save_image() {
      const updating_type = this.selecting_icon ?
        "icon" : this.selecting_banner ? "brand_banner" : "favicon"

      this.image_delete_toggle({
        images: [
          typeof this.rest_config[updating_type] === "string" ? 
            this.rest_config?.[updating_type] : Object?.values(this.rest_config[updating_type] || {})
        ],
        store_type: CONFIGURATION
      })

      const image_sources = await upload_image(
        this.uploaded_image,
        `project_images/${this.uploaded_image.file.name}`,
        true,
        true,
        false,
        this.selecting_banner,
        // Only if uploading icon and image being a png, due to poor support for transparent webp in emails
        !this.selecting_icon
      )

      if (!image_sources) return

      this.update_project_rest_config([
        updating_type,
        image_sources[this.selecting_banner ? image_dimension_names.tablet : image_dimension_names.mobile],
        this.selecting_banner ? image_sources[image_dimension_names.mobile] : null
      ])
      this.configuration_data_updated({ types_updated: ["rest_config"] })
      this.open_image_modal = false
      this.uploaded_image = ""
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/admin_image_upload.scss";

  .logo-brand-config {
    &__modal {
      padding: 0 $default-size;

      &-content {
        text-align: center;
        padding-bottom: $default-size;

        img {
          display: block;
          margin: 0 0 $default-size;
          padding: 0 $default-size;
          max-height: 300px;
        }
      }
    }

    &__section {
      margin-left: $default-size;

      @media (max-width: $mobile--large) {
        margin-left: 0;
      }

      img {
        max-width: 600px;
        max-height: 300px;
        padding: $default-size;
        border-radius: 5px;
        margin-top: $half-default-size;
        width: 100%;

        @media (max-width: $mobile--large) {
          width: calc(100% - $half-default-size);
          margin: 0 #{$half-default-size / 2};
          padding: $half-default-size;
        }
      }

      &--icon {
        img {
          max-width: calc(#{$menu-height + $default-size});
          max-height: calc(#{$menu-height + $default-size});
          padding: $half-default-size;
          border-radius: 5px;
        }
      }

      .md-body-1 {
        margin-top: 0;

        b {
          color: $material-red;
        }
      }

      .md-title {
        margin-bottom: $half-default-size;
      }

      button {
        display: block;
        margin: $default-size 0;
      }
    }
  }
</style>
