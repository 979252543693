<template>
  <section class="shopify-configurator scroll-content">
    <h3 class="md-display-1">{{ $translate("shopify_configurator.title") }}:</h3>
    <p class="md-body-1">{{ $translate("shopify_configurator.message") }}</p>

    <div class="shopify-configurator__how-to">
      <p class="md-body-1">
        <span>{{ $translate("shopify_configurator.how_to") }}</span>
        <md-button
          class="shopify-configurator__how-to-button"
          :class="{ 'md-primary': !open_how_to, 'md-accent': open_how_to }"
          @click="open_how_to = !open_how_to"
        >
          {{ $translate(`shopify_configurator.${open_how_to ? 'hide_video' : 'show_video'}`) }}
        </md-button>
      </p>
      <transition name="slide-how-to">
        <videoPlayer
          v-if="open_how_to"
          :options="player_options"
        />
      </transition>
    </div>

    <div class="shopify-configurator__key">
      <div class="md-title">{{ $translate(`shopify_configurator.values.should_use_shopify_data.title`) }}:</div>
      <p class="md-body-1">{{ $translate(`shopify_configurator.values.should_use_shopify_data.message`) }}</p>
      <md-checkbox v-model="should_use_shopify_data" :disabled="!is_shopify_configured || !can_user_manage_configuration">
        {{ $translate("shopify_configurator.button") }}
      </md-checkbox>
    </div>

    <div
      v-for="[key, val] in Object.entries(correct_config.shopify_public)"
      :key="`shopify-public-${key}`"
      class="shopify-configurator__key"
    >
      <div class="md-title">{{ $translate(`shopify_configurator.values.${key}.title`) }}:</div>
      <p class="md-body-1">{{ $translate(`shopify_configurator.values.${key}.message`) }}</p>
      <md-field>
        <label>{{ $translate(`shopify_configurator.values.${key}.title`) }}</label>
        <md-input
          :disabled="!can_user_manage_configuration"
          :value="val"
          @change="event => update_config('shopify_public', key, event.target.value)"
        />
      </md-field>
    </div>

    <div
      v-for="[key, val] in Object.entries(correct_config.shopify_secret)"
      :key="`shopify-secret-${key}`"
      class="shopify-configurator__key"
    >
      <div class="md-title">{{ $translate(`shopify_configurator.values.${key}.title`) }}:</div>
      <p class="md-body-1">{{ $translate(`shopify_configurator.values.${key}.message`) }}</p>
      <md-field>
        <label>{{ $translate(`shopify_configurator.values.${key}.title`) }}</label>
        <md-input
          :disabled="!can_user_manage_configuration"
          :value="val"
          @change="event => update_config('shopify_secret', key, event.target.value)"
        />
      </md-field>
    </div>

    <md-snackbar
      class="shopify-configurator__message"
      md-position="center"
      :md-active="(
        !access_data.length || !is_shopify_configured_correctly
      ) && can_user_manage_configuration && is_shopify_configured"
      md-persistent
      :class="{
        'md-alert': !access_data.length,
        'md-warning': access_data.length
      }"
    >
      <span>{{ $translate(`shopify_configurator.${!access_data.length ? "incorrect_credentials" : "incorrect_scopes"}`) }}</span>

      <md-button class="md-icon-button" @click="check_if_access_correct">
        <md-icon>cached</md-icon>
        <md-tooltip md-direction="top">{{ $translate("shopify_configurator.retest_connection") }}</md-tooltip>
      </md-button>
    </md-snackbar>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { videoPlayer } from "vue-video-player"
import { ADMIN, CONFIGURATION_STORE } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED, UPDATE_SMALL_LOADER } from "../../../stores/Admin/constants"
import { UPDATE_SHOPIFY_CONFIG } from "../../../stores/Admin/configuration/constants"
import empty_shopify_configuration from "../../../constants/empty_objects/empty_shopify_configuration"
import shopify_connection_check from "../../../methods/shopify_connection_check"
import "video.js/dist/video-js.css"

export default {
  components: {
    videoPlayer
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      open_how_to: false,
      is_shopify_configured_correctly: false,
      player_options: {
        language: "en",
        sources: [{
          type: "video/mp4",
          src: "/static/video/shopify_API_setup.mp4"
        }],
        // poster: "" TODO add poster to video
      },
      access_data: [{ handle: "" }] // add default value to not show error when no need
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["shopify_config"]),
    correct_config() {
      return this.shopify_config.shopify_public ? this.shopify_config : empty_shopify_configuration
    },
    should_use_shopify_data: {
      get() { return !!this.correct_config.should_use_shopify_data },
      set(val) {
        this.update_config(undefined, "should_use_shopify_data", val)
        this.configuration_data_updated({ types_updated: ["shopify_config"] })
      }
    },
    is_shopify_configured() {
      return [
        ...Object.values(this.correct_config.shopify_public),
        ...Object.values(this.correct_config.shopify_secret)
      ].every(val => val)
    }
  },
  watch: {
    is_shopify_configured(val) {
      if (!val) this.update_config(undefined, "should_use_shopify_data", false)
    }
  },
  mounted() {
    this.check_if_access_correct(true)
    if (!this.is_shopify_configured) this.open_how_to = true
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_shopify_config: UPDATE_SHOPIFY_CONFIG
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
      update_small_loader: UPDATE_SMALL_LOADER
    }),
    is_shopify_config_filled_out() {
      return [
        ...Object.values(this.correct_config.shopify_public),
        ...Object.values(this.correct_config.shopify_secret)
      ].every(val => val)
    },
    update_config(parent_key, key, value) {
      this.update_shopify_config([parent_key, key, value])
      this.check_if_access_correct()
      this.configuration_data_updated({ types_updated: ["shopify_config"] })
    },
    async check_if_access_correct(initial_call = false) {
      this.update_small_loader(true)

      const { is_shopify_configured_correctly, shopify_access_scopes } = await shopify_connection_check(this.correct_config)
      this.is_shopify_configured_correctly = is_shopify_configured_correctly
      this.access_data = shopify_access_scopes || []

      this.update_small_loader(false)

      if (!initial_call) this.configuration_data_updated({ types_updated: ["shopify_config"], value: is_shopify_configured_correctly })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .shopify-configurator {
    padding-bottom: 60px; // For ther connection check bar

    &__key {
      margin-bottom: $default-size;
      padding: $half-default-size $default-size;
      border-left: 1px solid $blue;

      span, button, div {
        display: inline-block;
        vertical-align: middle;
      }

      span {
        width: 50px;
        height: 50px;
        margin-left: $default-size;
        border-radius: 100%;
      }

      .md-field {
        width: 300px !important;
      }
    }

    &__how-to {
      margin-bottom: $double-default-size;

      span {
        width: 50%;
        max-width: 600px;
        padding-right: $default-size;
        border-right: 1px solid $blue;
        word-break: break-word;
      }

      span, &-button {
        display: inline-block;
        vertical-align: middle;
      }
    }

    .video-player {
      width: 100%;
      max-width: 800px;

      .video-js {
        width: 100%;
        height: 450px;
      }

      .vjs-big-play-button {
        top: calc(50% - #{$default-size}) !important;
        left: calc(50% - 45px) !important;
        width: 90px !important;
        height: 45px !important;
      }
    }

    &__message {
      font-weight: bold;
      max-width: 800px;

      .md-snackbar-content {
        display: block;

        span {
          display: inline-block;
          width: calc(100% - 65px);
        }
        
        button {
          display: inline-block !important;
          width: $double-default-size;
          height: $double-default-size;
          margin: 0 0 0 $default-size;
          vertical-align: middle;
        }
      }
    }
  }

  .slide-how-to-enter-active, .slide-how-to-leave-active {
    transition: .5s;
  }
  .slide-how-to-enter, .slide-how-to-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(-#{$default-size});
  }
</style>
