<template>
  <section class="translations-editor scroll-content">
    <h3 class="md-display-1">{{ $translate("translations_editor.special_page_translations") }}:</h3>

    <language_switcher
      v-if="can_user_manage_configuration"
      :selected_language="selected_language"
      :show_title="false"
      :show_all_languages="false"
      @change-language="lang => switch_language(lang)"
    />

    <hr>

    <div class="md-body-1">
      <div v-html="$translate('translations_editor.footer_message')" />
      <span @click="$router.push(`/Admin/content/footer`)">
        {{ $translate("translations_editor.footer_page_link") }}
      </span>
    </div>

    <md-list v-if="!switching_language">
      <md-list-item md-expand>
        <span class="md-list-item-text">{{ $translate("translations_editor.privacy_policy") }}</span>

        <div slot="md-expand">
          <text_and_html_editor
            :disabled="!can_user_manage_configuration"
            :value="(
              (other_page_translations[selected_language] || {}).privacy_policy ||
              (default_legal_page_translations[selected_language] || {}).privacy_policy ||
              default_legal_page_translations[default_language].privacy_policy
            )"
            @input="value => update_translations('privacy_policy', value)"
          />
        </div>
      </md-list-item>

      <md-list-item md-expand>
        <span class="md-list-item-text">{{ $translate("translations_editor.return_policy") }}</span>

        <div slot="md-expand">
          <text_and_html_editor
            :disabled="!can_user_manage_configuration"
            :value="(
              (other_page_translations[selected_language] || {}).return_policy ||
              (default_legal_page_translations[selected_language] || {}).return_policy ||
              default_legal_page_translations[default_language].return_policy
            )"
            @input="value => update_translations('return_policy', value)"
          />
        </div>
      </md-list-item>

      <md-list-item md-expand>
        <span class="md-list-item-text">{{ $translate("translations_editor.terms_and_condition") }}</span>

        <div slot="md-expand">
          <text_and_html_editor
            :disabled="!can_user_manage_configuration"
            :value="(
              (other_page_translations[selected_language] || {}).terms_and_conditions ||
              (default_legal_page_translations[selected_language] || {}).terms_and_conditions ||
              default_legal_page_translations[default_language].terms_and_conditions
            )"
            @input="value => update_translations('terms_and_conditions', value)"
          />
        </div>
      </md-list-item>
    </md-list>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { ADMIN, CONFIGURATION_STORE, translation_objects } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import {
  UPDATE_LEGAL_PAGE_TRANSLATIONS,
  REMOVE_LEGAL_PAGE_TRANSLATION
} from "../../../stores/Admin/configuration/constants"
import default_translations_object from "../../../constants/empty_objects/default_translations_object"
import default_legal_translations_object_cz from "../../../constants/empty_objects/default_legal_translations_object_cz"
import default_legal_translations_object_en from "../../../constants/empty_objects/default_legal_translations_object_en"
import { languages, default_language, allowed_currencies, allowed_currency_signs } from "../../../../Shared/constants/other"
import text_and_html_editor from "../text_and_html_editor"
import language_switcher from "../language_switcher"

export default {
  components: {
    text_and_html_editor,
    language_switcher
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show_translations: true,
      show_global_translations: true,
      switching_language: false,
      number_of_loaded_editors: 0,
      selected_language: "",
      default_legal_page_translations: {
        en: default_legal_translations_object_en,
        cz: default_legal_translations_object_cz
      },
      languages,
      allowed_currencies,
      allowed_currency_signs,
      default_language
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["other_page_translations", "translations"]),
    selected_translations() {
      return Object
        .entries(this.translations[this.selected_language] || translation_objects[this.selected_language] || default_translations_object)
        .flatMap(([key, val]) => ({
          key,
          val: (typeof val === "string" || typeof val === "number") ?
            val : Object.entries(val).map(([sub_key, sub_val]) => ({ key: sub_key, val: sub_val }))
        }))
    },
    correct_flag_image() {
      return `/static/icons/countries/${this.selected_language}.svg?${this.selected_language}`
    },
    can_user_remove_language() {
      return Object.keys(this.other_page_translations).length > 1
    },
    currency_code: {
      get() { return this.translations[this.selected_language].currency_code },
      set(value) {
        this.update_translations("currency_code", value)
      }
    },
    currency_symbol: {
      get() { return this.translations[this.selected_language].currency_symbol },
      set(value) {
        this.update_translations("currency_symbol", value)
      }
    },
    tax_percantage: {
      get() { return this.translations[this.selected_language].tax_percantage || "" },
      set(value) {
        this.update_translations("tax_percantage", value)
      }
    }
  },
  watch: {
    can_user_remove_language() {
      this.show_translations = false
      this.$nextTick(() => this.show_translations = true) // Needed for element re-render and flag change
    },
  },
  created() {
    this.selected_language = (Object.keys(this.translations) || [])[0] || default_language
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_legal_page_translation: UPDATE_LEGAL_PAGE_TRANSLATIONS,
      remove_legal_page_translation: REMOVE_LEGAL_PAGE_TRANSLATION
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED
    }),
    switch_language(language) {
      /**
       * Because switching language for legal pages took very long
       * this ensures a faster switch
       */
      this.selected_language = language
      this.switching_language = true

      this.$nextTick(() => {
        this.switching_language = false
      })
    },
    update_translations(key, value) {
      if (this.switching_language) return
      // Check that text/html editors loaded with data before enabling change
      if (
        this.number_of_loaded_editors !== Object.values(
          (this.other_page_translations[this.selected_language] || {})
        ).filter(val => val).length
      ) {
        ++this.number_of_loaded_editors
        return
      }

      this.update_legal_page_translation([key, value, this.selected_language])
      this.configuration_data_updated({ types_updated: ["other_page_translations"] })
    },
    remove_selected_translation() {
      this.remove_legal_page_translation(this.selected_language)
      this.configuration_data_updated({ types_updated: ["other_page_translations"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/flag_change.scss";

  .translations-editor {
    img {
      width: 50px;
      cursor: pointer;
    }

    &__buttons {
      float: right;

      @media (max-width: $tablet--small) {
        float: unset;
      }
    }

    &__top-messages {
      width: fit-content;
      padding: $half-default-size;
      background-color: $material-red;
      border-radius: $border-radius;
      color: $pure-white;
    }

    &__empty-language {
      opacity: 0.5;
      filter: none !important;
      cursor: initial !important;
    }

    .md-table {
      width: 100%;

      &-toolbar {
        display: block;
        line-height: 64px;
      }
    }

    .md-body-1 {
      span {
        color: $blue;
        cursor: pointer;
      }
    }

    .ql-toolbar {
      overflow: auto;
    }

    &__currency {
      max-width: 200px;
    }

    &__inputs {
      .md-field {
        width: auto;
        min-width: 300px;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        filter: drop-shadow(0 0 10px rgba($pure-black,0.5));
      }

      &--block {
        display: block !important;
      }

      .md-table-content {
        height: auto !important;
        max-height: unset !important;
      }
    }

    svg {
      max-width: 400px;
      max-height: 400px;
    }
  }
</style>
