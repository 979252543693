<template>
  <section class="configuration-content">
    <save_button
      :show="show_save_button && permissions.configuration.module"
      :section="$translate('side_menu.store_config_admin')"
      @save="save_changed_configuration"
      @cancel="re_fetch_data"
    />
    <error_bar :error_list="error_list"/>


    <store_live_preview_editors v-if="show_store_preview" :show_about_page="false">
      <style_configurator
        v-if="configuration_type === style_config"
        :can_user_manage_configuration="permissions.configuration.sections.styling.manage"
      />
      <logo_and_brand_configurator
        v-else-if="configuration_type === logo_upload"
        :can_user_manage_configuration="permissions.configuration.sections.styling.manage"
      />
    </store_live_preview_editors>

    <section
      v-else-if="(
        (
          permissions.configuration.sections.translations.module ||
          permissions.configuration.sections.shopify.module ||
          permissions.configuration.sections.shipping.module ||
          permissions.configuration.sections.other_configuration.module ||
          permissions.configuration.sections.project_configuration.module ||
          permissions.configuration.sections.payment_gateway.module
        ) && data_loaded
      )"
    >
      <translations_editor
        v-if="configuration_type === translations && permissions.configuration.sections.translations.module"
        :can_user_manage_configuration="permissions.configuration.sections.translations.manage"
      />
      <legal_translations_editor
        v-if="configuration_type === legal_translations && permissions.configuration.sections.translations.module"
        :can_user_manage_configuration="permissions.configuration.sections.translations.manage"
      />
      <page_type_translations
        v-else-if="configuration_type === miscrosite_translations && permissions.configuration.sections.translations.module"
        :can_user_manage_configuration="permissions.configuration.sections.translations.manage"
      />
      <splash_image_configurator
        v-else-if="configuration_type === splash_image_config && permissions.configuration.sections.styling.module"
        :can_user_manage_configuration="permissions.configuration.sections.styling.manage"
      />
      <shopify_configurator
        v-else-if="configuration_type === shopify && permissions.configuration.sections.shopify.module"
        :can_user_manage_configuration="permissions.configuration.sections.shopify.manage"
      />
      <shipping_options_configurator
        v-else-if="configuration_type === shipping && permissions.configuration.sections.shipping.module"
        :can_user_manage_configuration="permissions.configuration.sections.shipping.manage"
      />
      <rest_configuration
        v-else-if="configuration_type === rest && permissions.configuration.sections.other_configuration.module"
        :can_user_manage_configuration="permissions.configuration.sections.other_configuration.manage"
      />
      <project_configuration
        v-else-if="configuration_type === project && permissions.configuration.sections.project_configuration.module"
        :can_user_manage_configuration="permissions.configuration.sections.project_configuration.manage"
      />
      <payment_gateway_configuration
        v-else-if="configuration_type === payment_gateway && permissions.configuration.sections.payment_gateway.module"
        :can_user_manage_configuration="permissions.configuration.sections.payment_gateway.manage"
      />
    </section>
    <p v-else-if="data_loaded" class="md-headline no-permissions">
      {{ $translate("no_permissions_message") }}
    </p>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex"
import {
  CONFIGURATION_STORE, ADMIN_STORE, translations, miscrosite_translations,
  style_config, splash_image_config, legal_translations,
  logo_upload, shopify, shipping, rest,
  project, payment_gateway, USER_STORE, PRODUCTS_STORE, severities, imported, CONTENT_STORE
} from "../../constants/others_constants"
import save_button from "../utils/save_button"
import error_bar from "../utils/error_bar"
import translations_editor from "../utils/configuration/translations_editor"
import page_type_translations from "../utils/configuration/page_type_translations"
import style_configurator from "../utils/configuration/style_configurator"
import splash_image_configurator from "../utils/configuration/splash_image_configurator"
import logo_and_brand_configurator from "../utils/configuration/logo_and_brand_configurator"
import shopify_configurator from "../utils/configuration/shopify_configurator"
import legal_translations_editor from "../utils/configuration/legal_translations_editor"
import shipping_options_configurator from "../utils/configuration/shipping_options_configurator"
import rest_configuration from "../utils/configuration/rest_configuration"
import project_configuration from "../utils/configuration/project_configuration"
import payment_gateway_configuration from "../utils/configuration/payment_gateway_configuration"
import { FETCH_FIREBASE_DATA, UPDATE_LOADER, CONFIGURATION_DATA_UPDATED, ADD_GLOBAL_ERROR, FETCH_SHOPIFY_DATA, FETCH_CONFIGURATION_DATA } from "../../stores/Admin/constants"
import { SAVE_CONFIGURATION, UPDATE_PRODUCTS_UPDATED_STATE } from "../../stores/Admin/configuration/constants"
import { user_domain_email_notification } from "../../constants/endpoints/other"
import { update_project_domains } from "../../constants/endpoints/firebase"
import { FETCH_IMPORTED_PRODUCTS, UPDATE_IMPORTED_COLLECTIONS, UPDATE_IMPORTED_PRODUCTS } from "../../stores/Admin/products/constants"
import { FETCH_CONTENT_DATA } from "../../stores/Admin/content/constants"
import store_live_preview_editors from "../layout/store_live_preview_editors"

export default {
  components: {
    translations_editor,
    save_button,
    error_bar,
    page_type_translations,
    style_configurator,
    splash_image_configurator,
    logo_and_brand_configurator,
    shopify_configurator,
    legal_translations_editor,
    shipping_options_configurator,
    rest_configuration,
    project_configuration,
    payment_gateway_configuration,
    store_live_preview_editors
  },
  data() {
    return {
      translations,
      miscrosite_translations,
      legal_translations,
      style_config,
      splash_image_config,
      logo_upload,
      shipping,
      shopify,
      rest,
      project,
      payment_gateway,
      modules_with_preview: [style_config, logo_upload],
      data_loaded: false
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, {
      error_list: "error_list",
      translations_content: "translations",
      single_product_homepages: "single_product_homepages",
      couple_products_homepages: "couple_products_homepages",
      rest_config: "rest_config",
      shopify_config: "shopify_config",
      shipping_options: "shipping_options",
      analytics_config: "analytics_config",
      project_config: "project_config",
      paypal_config: "paypal_config",
      other_page_translations: "other_page_translations",
      project_name: "project_name",
      products_updated: "products_updated",
      project_styles: "project_styles"
    }),
    ...mapState(CONTENT_STORE, ["footer_content"]),
    ...mapState(ADMIN_STORE, ["user_edited_website_configuration"]),
    ...mapState(USER_STORE, ["permissions", "user_info"]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_products"
    ]),
    show_save_button() {
      return !!Object.values(this.user_edited_website_configuration).length &&
        Object.values(this.user_edited_website_configuration).every(val => val) &&
        !this.error_list.length
    },
    configuration_type() {
      return this.$route.params.configuration_type
    },
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    show_store_preview() {
      return this.modules_with_preview.includes(this.configuration_type) &&
        this.permissions.configuration.sections.styling.manage &&
        [`${this.products_type}_products`] &&
        this.data_loaded
    },
  },
  async mounted() {
    if (!this.permissions.configuration.module) this.$router.push({ name: "Admin" })

    this.update_loader()
    await Promise.all([
      // Check if product are fetched
      !this[`${this.products_type}_products`] ? await this.fetch_products() : null,
      // Check if configuration fetched
      !Object.keys(this.translations_content).length ? await this.fetch_configuration() : null,
      // Check if content fetched
      ([style_config, logo_upload].includes(this.configuration_type) && !this.footer_content) ? await this.fetch_content() : null,
    ])

    if (this.project_styles) Object.entries(this.project_styles.colors).forEach(([key, val]) =>
      document.documentElement.style.setProperty(`--${key}`, val)
    )

    this.update_loader(false)
    
    this.data_loaded = true
  },
  async beforeUpdate() {
    if ([style_config, logo_upload].includes(this.configuration_type) && !this.footer_content) {
      this.data_loaded = false
      this.update_loader()
      await this.fetch_content()
      this.update_loader(false)
      this.data_loaded = true
    }
  },
  methods: {
    ...mapMutations(ADMIN_STORE, {
      configuration_data_update: CONFIGURATION_DATA_UPDATED,
      update_loader: UPDATE_LOADER,
      add_global_error: ADD_GLOBAL_ERROR
    }),
    ...mapActions(PRODUCTS_STORE, {
      update_imported_products: UPDATE_IMPORTED_PRODUCTS,
      update_imported_collections: UPDATE_IMPORTED_COLLECTIONS,
      fetch_imported_data: FETCH_IMPORTED_PRODUCTS
    }),
    ...mapMutations(CONFIGURATION_STORE, {
      update_products_updated_state: UPDATE_PRODUCTS_UPDATED_STATE
    }),
    ...mapActions(CONTENT_STORE, {
      fetch_content: FETCH_CONTENT_DATA
    }),
    ...mapActions(CONFIGURATION_STORE, {
      save_configuration: SAVE_CONFIGURATION,
      fetch_configuration: FETCH_CONFIGURATION_DATA
    }),
    ...mapActions(ADMIN_STORE, {
      fetch_data: FETCH_FIREBASE_DATA,
      fetch_shopify_data: FETCH_SHOPIFY_DATA
    }),
    async fetch_products() {
      this.update_loader(true)

      await this[`fetch_${this.products_type}_data`]()

      this.update_loader(false)
    },
    async save_changed_configuration() {
      this.update_loader()
      try {
        if (this.project_config.domain_updated && this.project_config.domain) {
          await update_project_domains({ domain: this.project_config.domain })
          await user_domain_email_notification({
            domain: this.project_config.domain,
            user_id: this.user_info.id,
            email: this.user_info.email,
            name: this.user_info.name,
            project_name: this.project_name
          })
          delete this.project_config.domain_updated
        }
        await this.save_configuration({
          translations: this.translations_content,
          single_product_homepages: this.single_product_homepages,
          couple_products_homepages: this.couple_products_homepages,
          project_styles: this.project_styles,
          rest_config: this.rest_config,
          shopify_config: this.shopify_config,
          shipping_options: this.shipping_options,
          analytics_config: this.analytics_config,
          project_config: this.project_config,
          paypal_config: this.paypal_config,
          other_page_translations: this.other_page_translations,
          project_name: this.project_name
        })
        this.configuration_data_update({})
      } catch ({ message }) {
        this.add_global_error({ message })
      }

      if (this.products_updated) this.add_global_error({
        message: this.$translate("errors.configuration.save_products_warning"),
        duration: 60000,
        severity: severities.WARNING,
        button: {
          text: this.$translate("save"),
          function: async () => {
            await this.update_imported_products([this.imported_products])
            await this.update_imported_collections([this.imported_collections])
            await this.fetch_imported_data()
            this.update_products_updated_state()
          }
        }
      })
      this.update_loader(false)
    },
    async re_fetch_data() {
      this.update_loader()
      await this.fetch_configuration()
      this.configuration_data_update({})
      this.update_loader(false)
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_admin-constants" as *;

  .configuration-content {
    .md-headline {
      border-bottom: 1px solid $blue;
      width: fit-content;
    }
    .md-title {
      font-size: 17px;
    }

    &__grid {
      display: grid;
      grid-template-columns: calc(100% - #{$double-default-size} - 400px) $double-default-size 400px;

      &--closed {
        margin-left: 0 !important;
        grid-template-columns: calc(100% - #{$double-default-size}) $double-default-size 0 !important;
        margin-right: -#{$material-size};
      }

      &--full-screen {
        margin-left: -16px;
        grid-template-columns: 0 40px calc(100% - 40px);
      }

      &--full-width {
        grid-template-columns: 0 $double-default-size 400px;
      }
    }

    &__preview-closer {
      position: relative;
      padding: calc(50vh - 130px - 35px) 13px;
      overflow: hidden;
      letter-spacing: 2px;
      word-break: break-all;
      text-align: center;
      font-size: 20px;
      line-height: 21px;
      background-color: $blue;
      color: $pure-white;
      cursor: pointer;

      div {
        height: $default-size;
      }

      &::before, &::after {
        position: absolute;
        content: "";
        background-image: url("/static/icons/arrow-white.png");
        background-size: 100% 100%;
        width: 20px;
        height: 20px;
        top: 30px;
        left: 10px;
      }

      &::after {
        top: auto;
        bottom: 30px;
      }

      &--show {
        &::before, &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
</style>
