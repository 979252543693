var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"translations-editor scroll-content"},[_c('h3',{staticClass:"md-display-1"},[_vm._v(_vm._s(_vm.$translate("translations_editor.special_page_translations"))+":")]),(_vm.can_user_manage_configuration)?_c('language_switcher',{attrs:{"selected_language":_vm.selected_language,"show_title":false,"show_all_languages":false},on:{"change-language":lang => _vm.switch_language(lang)}}):_vm._e(),_c('hr'),_c('div',{staticClass:"md-body-1"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$translate('translations_editor.footer_message'))}}),_c('span',{on:{"click":function($event){return _vm.$router.push(`/Admin/content/footer`)}}},[_vm._v(" "+_vm._s(_vm.$translate("translations_editor.footer_page_link"))+" ")])]),(!_vm.switching_language)?_c('md-list',[_c('md-list-item',{attrs:{"md-expand":""}},[_c('span',{staticClass:"md-list-item-text"},[_vm._v(_vm._s(_vm.$translate("translations_editor.privacy_policy")))]),_c('div',{attrs:{"slot":"md-expand"},slot:"md-expand"},[_c('text_and_html_editor',{attrs:{"disabled":!_vm.can_user_manage_configuration,"value":(
            (_vm.other_page_translations[_vm.selected_language] || {}).privacy_policy ||
            (_vm.default_legal_page_translations[_vm.selected_language] || {}).privacy_policy ||
            _vm.default_legal_page_translations[_vm.default_language].privacy_policy
          )},on:{"input":value => _vm.update_translations('privacy_policy', value)}})],1)]),_c('md-list-item',{attrs:{"md-expand":""}},[_c('span',{staticClass:"md-list-item-text"},[_vm._v(_vm._s(_vm.$translate("translations_editor.return_policy")))]),_c('div',{attrs:{"slot":"md-expand"},slot:"md-expand"},[_c('text_and_html_editor',{attrs:{"disabled":!_vm.can_user_manage_configuration,"value":(
            (_vm.other_page_translations[_vm.selected_language] || {}).return_policy ||
            (_vm.default_legal_page_translations[_vm.selected_language] || {}).return_policy ||
            _vm.default_legal_page_translations[_vm.default_language].return_policy
          )},on:{"input":value => _vm.update_translations('return_policy', value)}})],1)]),_c('md-list-item',{attrs:{"md-expand":""}},[_c('span',{staticClass:"md-list-item-text"},[_vm._v(_vm._s(_vm.$translate("translations_editor.terms_and_condition")))]),_c('div',{attrs:{"slot":"md-expand"},slot:"md-expand"},[_c('text_and_html_editor',{attrs:{"disabled":!_vm.can_user_manage_configuration,"value":(
            (_vm.other_page_translations[_vm.selected_language] || {}).terms_and_conditions ||
            (_vm.default_legal_page_translations[_vm.selected_language] || {}).terms_and_conditions ||
            _vm.default_legal_page_translations[_vm.default_language].terms_and_conditions
          )},on:{"input":value => _vm.update_translations('terms_and_conditions', value)}})],1)])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }