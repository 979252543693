export const terms_and_conditions = `
<p class="legal-pages__title--main">OBCHODNÍ PODMÍNKY</p>
<p class="legal-pages__message--main">obchodní podmínky společnosti <b>XXXXXXXX</b></p>
<p>se sídlem:&nbsp;<b>XXXXXXXX</b> IČO:&nbsp;<b>XXXXXXXX</b> zapsané v obchodním rejstříku vedeném&nbsp;<b>XXXXXXXX</b>.</p>
<p class="legal-pages__message--main">pro prodej zboží prostřednictvím on-line obchodu umístěného na internetové adrese <b>XXXXXXXX</b>.</p>
<p class="legal-pages__title--sub">1. ÚVODNÍ USTANOVENÍ</p>
<p>
  1.1. Tyto obchodní podmínky (dále jen „<b>obchodní podmínky</b>“) obchodní společnosti&nbsp;<b>XXXXXXXX</b>, se sídlem&nbsp;<b>XXXXXXXX</b>, IČO:&nbsp;<b>XXXXXXXX</b>, zapsané v obchodním rejstříku vedeném&nbsp;<b>XXXXXXXX</b>&nbsp;(dále
  jen „<b>prodávající</b>“) upravují v souladu s ustanovením § 1751 odst. 1 zákona č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů (dále jen „<b>občanský zákoník</b>“) vzájemná práva a povinnosti smluvních stran vzniklé v
  souvislosti nebo na základě kupní smlouvy (dále jen „<b>kupní smlouva</b>“) uzavírané mezi prodávajícím a jinou fyzickou či právnickou osobou (dále jen „<b>kupující</b>“) prostřednictvím internetového obchodu prodávajícího. Internetový
  obchod je prodávajícím provozován na webové stránce umístěné na internetové adrese <b>XXXXXXXX</b> (dále jen „<b>webová stránka</b>“), a to prostřednictvím rozhraní webové stránky (dále jen „<b>webové rozhraní obchodu</b>“).
</p>
<p>
  1.2. Obchodní podmínky se nevztahují na případy, kdy osoba, která má v úmyslu nakoupit zboží od prodávajícího, je právnickou osobou či osobou, jež jedná při objednávání zboží v rámci své podnikatelské činnosti nebo v rámci svého
  samostatného výkonu povolání.
</p>
<p>
  1.3. Ustanovení odchylná od obchodních podmínek je možné sjednat v kupní smlouvě. Odchylná ujednání v kupní smlouvě mají přednost před ustanoveními obchodních podmínek.
</p>
<p>
  1.4. Ustanovení obchodních podmínek jsou nedílnou součástí kupní smlouvy. Kupní smlouva a obchodní podmínky jsou vyhotoveny v českém jazyce. Kupní smlouvu lze uzavřít v českém jazyce.
</p>
<p>
  1.5. Znění obchodních podmínek může prodávající měnit či doplňovat. Tímto ustanovením nejsou dotčena práva a povinnosti vzniklá po dobu účinnosti předchozího znění obchodních podmínek.
</p>
<p class="legal-pages__title--sub">2. UŽIVATELSKÝ ÚČET</p>
<p>
  2.1. Na základě registrace kupujícího provedené na webové stránce může kupující přistupovat do svého uživatelského rozhraní. Ze svého uživatelského rozhraní může kupující provádět objednávání zboží (dále jen „<b>uživatelský účet</b>“). V
  případě, že to webové rozhraní obchodu umožňuje, může kupující provádět objednávání zboží též bez registrace přímo z webového rozhraní obchodu.
</p>
<p>
  2.2. Při registraci na webové stránce a při objednávání zboží je kupující povinen uvádět správně a pravdivě všechny údaje. Údaje uvedené v uživatelském účtu je kupující při jakékoliv jejich změně povinen aktualizovat. Údaje uvedené
  kupujícím v uživatelském účtu a při objednávání zboží jsou prodávajícím považovány za správné.
</p>
<p>
  2.3. Přístup k uživatelskému účtu je zabezpečen uživatelským jménem a heslem. Kupující je povinen zachovávat mlčenlivost ohledně informací nezbytných k přístupu do jeho uživatelského účtu.
</p>
<p>
  2.4. Kupující není oprávněn umožnit využívání uživatelského účtu třetím osobám.
</p>
<p>
  2.5. Prodávající může zrušit uživatelský účet, a to zejména v případě, kdy kupující svůj uživatelský účet déle než <b>12 měsíců</b>
  nevyužívá, či v případě, kdy kupující poruší své povinnosti z kupní smlouvy (včetně obchodních podmínek).
</p>
<p>
  2.6. Kupující bere na vědomí, že uživatelský účet nemusí být dostupný nepřetržitě, a to zejména s ohledem na nutnou údržbu hardwarového a softwarového vybavení prodávajícího, popř. nutnou údržbu hardwarového a softwarového vybavení
  třetích osob.
</p>
<p class="legal-pages__title--sub">3. UZAVŘENÍ KUPNÍ SMLOUVY</p>
<p>
  3.1. Veškerá prezentace zboží umístěná ve webovém rozhraní obchodu je informativního charakteru a prodávající není povinen uzavřít kupní smlouvu ohledně tohoto zboží. Ustanovení § 1732 odst. 2 občanského zákoníku se nepoužije.
</p>
<p>
  3.2. Webové rozhraní obchodu obsahuje informace o zboží, a to včetně uvedení cen jednotlivého zboží a nákladů za navrácení zboží, jestliže toto zboží ze své podstaty nemůže být navráceno obvyklou poštovní cestou. Ceny zboží jsou uvedeny
  včetně daně z přidané hodnoty a všech souvisejících poplatků. Ceny zboží zůstávají v platnosti po dobu, kdy jsou zobrazovány ve webovém rozhraní obchodu. Tímto ustanovením není omezena možnost prodávajícího uzavřít kupní smlouvu za
  individuálně sjednaných podmínek.
</p>
<p>
  3.3. Webové rozhraní obchodu obsahuje také informace o nákladech spojených s balením a dodáním zboží. Informace o nákladech spojených s balením a dodáním zboží uvedené ve webovém rozhraní obchodu platí pouze v případech, kdy je zboží
  doručováno v rámci území České republiky.
</p>
<p>
  3.4. Pro objednání zboží vyplní kupující objednávkový formulář ve webovém rozhraní obchodu. Objednávkový formulář obsahuje zejména informace o:
</p>
<p>
  3.4.1. objednávaném zboží (objednávané zboží „vloží“ kupující do elektronického nákupního košíku webového rozhraní obchodu),
</p>
<p>
  3.4.2. způsobu úhrady kupní ceny zboží, údaje o požadovaném způsobu doručení objednávaného zboží a
</p>
<p>
  3.4.3. informace o nákladech spojených s dodáním zboží (dále společně jen jako „<b>objednávka</b>“).
</p>
<p>
  <br />
</p>
<p>
  3.5. Před zasláním objednávky prodávajícímu je kupujícímu umožněno zkontrolovat a měnit údaje, které do objednávky kupující vložil, a to i s ohledem na možnost kupujícího zjišťovat a opravovat chyby vzniklé při zadávání dat do objednávky.
  Objednávku odešle kupující prodávajícímu kliknutím na tlačítko „<b>Dokončit objednávku</b>“. Údaje uvedené v objednávce jsou prodávajícím považovány za správné. Prodávající neprodleně po obdržení objednávky toto obdržení kupujícímu
  potvrdí elektronickou poštou, a to na adresu elektronické pošty kupujícího uvedenou v uživatelském účtu či v objednávce (dále jen „<b>elektronická adresa kupujícího</b>“).
</p>
<p>
  3.6. Prodávající je vždy oprávněn v závislosti na charakteru objednávky (množství zboží, výše kupní ceny, předpokládané náklady na dopravu) požádat kupujícího o dodatečné potvrzení objednávky (například písemně či telefonicky).
</p>
<p>
  3.7. Smluvní vztah mezi prodávajícím a kupujícím vzniká doručením přijetí objednávky (akceptací), jež je prodávajícím zasláno kupujícímu elektronickou poštou, a to na adresu elektronické pošty kupujícího.
</p>
<p>
  3.8. Kupující souhlasí s použitím komunikačních prostředků na dálku při uzavírání kupní smlouvy. Náklady vzniklé kupujícímu při použití komunikačních prostředků na dálku v souvislosti s uzavřením kupní smlouvy (náklady na internetové
  připojení, náklady na telefonní hovory) si hradí kupující sám, přičemž tyto náklady se neliší od základní sazby.
</p>
<p class="legal-pages__title--sub">4. CENA ZBOŽÍ A PLATEBNÍ PODMÍNKY</p>
<p>
  4.1. Cenu zboží a případné náklady spojené s dodáním zboží dle kupní smlouvy může kupující uhradit prodávajícímu následujícími způsoby:
</p>
<ul>
  <li>
    <p>
      bezhotovostně prostřednictvím platebního systému&nbsp;<b>PayPal</b>;
    </p>
  </li>
  <li>
    <p align="justify" style="margin-bottom: 0.08in; line-height: 0.26in; background: #ffffff;">
      bezhotovostně platební kartou;
    </p>
  </li>
</ul>
<p>
  4.2. Společně s kupní cenou je kupující povinen zaplatit prodávajícímu také náklady spojené s balením a dodáním zboží ve smluvené výši. Není-li uvedeno výslovně jinak, rozumí se dále kupní cenou i náklady spojené s dodáním zboží.
</p>
<p>
  4.3. Prodávající nepožaduje od kupujícího zálohu či jinou obdobnou platbu. Tímto není dotčeno ustanovení čl. 4.6 obchodních podmínek ohledně povinnosti uhradit kupní cenu zboží předem.
</p>
<p>
  4.4. V případě platby v hotovosti či v případě platby na dobírku je kupní cena splatná při převzetí zboží. V případě bezhotovostní platby je kupní cena splatná do <b>14 dnů</b>
  od uzavření kupní smlouvy.
</p>
<p>
  4.5. V případě bezhotovostní platby je kupující povinen uhrazovat kupní cenu zboží společně s uvedením variabilního symbolu platby. V případě bezhotovostní platby je závazek kupujícího uhradit kupní cenu splněn okamžikem připsání
  příslušné částky na účet prodávajícího.
</p>
<p>
  4.6. Prodávající je oprávněn, zejména v případě, že ze strany kupujícího nedojde k dodatečnému potvrzení objednávky (čl. 3.6), požadovat uhrazení celé kupní ceny ještě před odesláním zboží kupujícímu. Ustanovení § 2119 odst. 1 občanského
  zákoníku se nepoužije.
</p>
<p>
  4.7. Případné slevy z ceny zboží poskytnuté prodávajícím kupujícímu nelze vzájemně kombinovat.
</p>
<p>
  4.8. Je-li to v obchodním styku obvyklé nebo je-li tak stanoveno obecně závaznými právními předpisy, vystaví prodávající ohledně plateb prováděných na základě kupní smlouvy kupujícímu daňový doklad – fakturu.
  Prodávající&nbsp;<b>je/není</b>&nbsp;plátcem daně z přidané hodnoty. Daňový doklad – fakturu vystaví prodávající kupujícímu po uhrazení ceny zboží a zašle jej v elektronické podobě na elektronickou adresu kupujícího.
</p>
<p>
  4.9. Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku. Zároveň je povinen zaevidovat přijatou tržbu u správce daně online; v případě technického výpadku pak nejpozději do 48 hodin.
</p>
<p class="legal-pages__title--sub">5. ODSTOUPENÍ OD KUPNÍ SMLOUVY</p>
<p>
  5.1. Kupující bere na vědomí, že dle ustanovení § 1837 občanského zákoníku nelze mimo jiné odstoupit od kupní smlouvy o dodávce zboží, které bylo upraveno podle přání kupujícího nebo pro jeho osobu, od kupní smlouvy o dodávce zboží, které
  podléhá rychlé zkáze, jakož i zboží, které bylo po dodání nenávratně smíseno s jiným zbožím, od kupní smlouvy o dodávce zboží v uzavřeném obalu, které spotřebitel z obalu vyňal a z hygienických důvodů jej není možné vrátit a od kupní
  smlouvy o dodávce zvukové nebo obrazové nahrávky nebo počítačového programu, pokud porušil jejich původní obal.
</p>
<p>
  5.2. Nejedná-li se o případ uvedený v čl. 5.1 obchodních podmínek či o jiný případ, kdy nelze od kupní smlouvy odstoupit, má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit, a to do
  čtrnácti (14) dnů od převzetí zboží, přičemž v případě, že předmětem kupní smlouvy je několik druhů zboží nebo dodání několika částí, běží tato lhůta ode dne převzetí poslední dodávky zboží. Odstoupení od kupní smlouvy musí být
  prodávajícímu odesláno ve lhůtě uvedené v předchozí větě. Pro odstoupení od kupní smlouvy může kupující využít vzorový formulář poskytovaný prodávajícím, jenž tvoří přílohu obchodních podmínek. Odstoupení od kupní smlouvy může kupující
  zasílat mimo jiné na adresu provozovny prodávajícího či na adresu elektronické pošty prodávajícího&nbsp;<b>XXXXXXXX</b>.
</p>
<p>
  5.3. V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek se kupní smlouva od počátku ruší. Zboží musí být kupujícím prodávajícímu vráceno do čtrnácti (14) dnů od doručení odstoupení od kupní smlouvy prodávajícímu.
  Odstoupí-li kupující od kupní smlouvy, nese kupující náklady spojené s navrácením zboží prodávajícímu, a to i v tom případě, kdy zboží nemůže být vráceno pro svou povahu obvyklou poštovní cestou.
</p>
<p>
  5.4. V případě odstoupení od kupní smlouvy dle čl. 5.2 obchodních podmínek vrátí prodávající peněžní prostředky přijaté od kupujícího do čtrnácti (14) dnů od odstoupení od kupní smlouvy kupujícím, a to stejným způsobem, jakým je
  prodávající od kupujícího přijal. Prodávající je taktéž oprávněn vrátit plnění poskytnuté kupujícím již při vrácení zboží kupujícím či jiným způsobem, pokud s tím kupující bude souhlasit a nevzniknou tím kupujícímu další náklady.
  Odstoupí-li kupující od kupní smlouvy, prodávající není povinen vrátit přijaté peněžní prostředky kupujícímu dříve, než mu kupující zboží vrátí nebo prokáže, že zboží prodávajícímu odeslal.
</p>
<p>
  5.5. Nárok na úhradu škody vzniklé na zboží je prodávající oprávněn jednostranně započíst proti nároku kupujícího na vrácení kupní ceny.
</p>
<p>
  5.6. V případech, kdy má kupující v souladu s ustanovením § 1829 odst. 1 občanského zákoníku právo od kupní smlouvy odstoupit, je prodávající také oprávněn kdykoliv od kupní smlouvy odstoupit, a to až do doby převzetí zboží kupujícím. V
  takovém případě vrátí prodávající kupujícímu kupní cenu bez zbytečného odkladu, a to bezhotovostně na účet určený kupujícím.
</p>
<p>
  5.7. Je-li společně se zbožím poskytnut kupujícímu dárek, je darovací smlouva mezi prodávajícím a kupujícím uzavřena s rozvazovací podmínkou, že dojde-li k odstoupení od kupní smlouvy kupujícím, pozbývá darovací smlouva ohledně takového
  dárku účinnosti a kupující je povinen spolu se zbožím prodávajícímu vrátit i poskytnutý dárek.
</p>
<p class="legal-pages__title--sub">6. PŘEPRAVA A DODÁNÍ ZBOŽÍ</p>
<p>
  6.1. V případě, že je způsob dopravy smluven na základě zvláštního požadavku kupujícího, nese kupující riziko a případné dodatečné náklady spojené s tímto způsobem dopravy.
</p>
<p>
  6.2. Je-li prodávající podle kupní smlouvy povinen dodat zboží na místo určené kupujícím v objednávce, je kupující povinen převzít zboží při dodání.
</p>
<p>
  6.3. V případě, že je z důvodů na straně kupujícího nutno zboží doručovat opakovaně nebo jiným způsobem, než bylo uvedeno v objednávce, je kupující povinen uhradit náklady spojené s opakovaným doručováním zboží, resp. náklady spojené s
  jiným způsobem doručení.
</p>
<p>
  6.4. Při převzetí zboží od přepravce je kupující povinen zkontrolovat neporušenost obalů zboží a v případě jakýchkoliv závad toto neprodleně oznámit přepravci. V případě shledání porušení obalu svědčícího o neoprávněném vniknutí do
  zásilky nemusí kupující zásilku od přepravce převzít. Tímto nejsou dotčena práva kupujícího z odpovědnosti za vady zboží a další práva kupujícího vyplývající z obecně závazných právních předpisů.
</p>
<p>
  6.5. Další práva a povinnosti stran při přepravě zboží mohou upravit zvláštní dodací podmínky prodávajícího, jsou-li prodávajícím vydány.
</p>
<p class="legal-pages__title--sub">7. PRÁVA Z VADNÉHO PLNĚNÍ</p>
<p>
  7.1. Práva a povinnosti smluvních stran ohledně práv z vadného plnění se řídí příslušnými obecně závaznými právními předpisy (zejména ustanoveními § 1914 až 1925, § 2099 až 2117 a § 2161 až 2174 občanského zákoníku a zákonem
  č.&nbsp;634/1992&nbsp;Sb., o ochraně spotřebitele, ve znění pozdějších předpisů).
</p>
<p>
  7.2. Prodávající odpovídá kupujícímu, že zboží při převzetí nemá vady. Zejména prodávající odpovídá kupujícímu, že v době, kdy kupující zboží převzal:
</p>
<p>
  7.2.1. má zboží vlastnosti, které si strany ujednaly, a chybí-li ujednání, má takové vlastnosti, které prodávající nebo výrobce popsal nebo které kupující očekával s ohledem na povahu zboží a na základě reklamy jimi prováděné,
</p>
<p>
  7.2.2. se zboží hodí k účelu, který pro jeho použití prodávající uvádí nebo ke kterému se zboží tohoto druhu obvykle používá,
</p>
<p>
  7.2.3. zboží odpovídá jakostí nebo provedením smluvenému vzorku nebo předloze, byla-li jakost nebo provedení určeno podle smluveného vzorku nebo předlohy,
</p>
<p>
  7.2.4. je zboží v odpovídajícím množství, míře nebo hmotnosti a
</p>
<p>
  7.2.5. zboží vyhovuje požadavkům právních předpisů.
</p>
<p>
  7.3. Projeví-li se vada v průběhu šesti měsíců od převzetí, má se za to, že zboží bylo vadné již při převzetí.
</p>
<p>
  7.4. Prodávající má povinnosti z vadného plnění nejméně v takovém rozsahu, v jakém trvají povinnosti z vadného plnění výrobce. Kupující je jinak oprávněn uplatnit právo z vady, která se vyskytne u spotřebního zboží v době dvaceti čtyř
  měsíců od převzetí. Je-li na prodávaném zboží, na jeho obalu, v návodu připojenému ke zboží nebo v reklamě v souladu s jinými právními předpisy uvedena doba, po kterou lze zboží použít, použijí se ustanovení o záruce za jakost. Zárukou za
  jakost se prodávající zavazuje, že zboží bude po určitou dobu způsobilé k použití pro obvyklý účel nebo že si zachová obvyklé vlastnosti. Vytkl-li kupující prodávajícímu vadu zboží oprávněně, neběží lhůta pro uplatnění práv z vadného
  plnění ani záruční doba po dobu, po kterou kupující nemůže vadné zboží užívat.
</p>
<p>
  7.5. Ustanovení uvedená v čl. 7.2 obchodních podmínek se nepoužijí u zboží prodávaného za nižší cenu na vadu, pro kterou byla nižší cena ujednána, na opotřebení zboží způsobené jeho obvyklým užíváním, u použitého zboží na vadu
  odpovídající míře používání nebo opotřebení, kterou zboží mělo při převzetí kupujícím, nebo vyplývá-li to z povahy zboží. Právo z vadného plnění kupujícímu nenáleží, pokud kupující před převzetím zboží věděl, že zboží má vadu, anebo pokud
  kupující vadu sám způsobil.
</p>
<p>
  7.6. Práva z odpovědnosti za vady zboží se uplatňují u prodávajícího. Je-li však v potvrzení vydaném prodávajícímu ohledně rozsahu práv z odpovědnosti za vady (ve smyslu ustanovení § 2166 občanského zákoníku) uvedena jiná osoba určená k
  opravě, která je v místě prodávajícího nebo v místě pro kupujícího bližším, uplatní kupující právo na opravu u toho, kdo je určen k provedení opravy. S výjimkou případů, kdy je k provedení opravy určena jiná osoba podle předchozí věty je
  prodávající povinen přijmout reklamaci v kterékoli provozovně, v níž je přijetí reklamace možné s ohledem na sortiment prodávaných výrobků nebo poskytovaných služeb, případně i v sídle nebo místě podnikání. Prodávající je povinen
  kupujícímu vydat písemné potvrzení o tom, kdy kupující právo uplatnil, co je obsahem reklamace a jaký způsob vyřízení reklamace kupující požaduje; a dále potvrzení o datu a způsobu vyřízení reklamace, včetně potvrzení o provedení opravy a
  době jejího trvání, případně písemné odůvodnění zamítnutí reklamace. Tato povinnost se vztahuje i na jiné osoby určené prodávajícím k provedení opravy.
</p>
<p>
  7.7. Práva z odpovědnosti za vady zboží může kupující konkrétně uplatnit zejména osobně na adrese <b>XXXXXXXX</b> telefonicky na čísle <b>XXXXXXXX</b> či elektronickou poštou na adrese <b>XXXXXXXX</b>.
</p>
<p>
  7.8. Kupující sdělí prodávajícímu, jaké právo si zvolil, při oznámení vady, nebo bez zbytečného odkladu po oznámení vady. Provedenou volbu nemůže kupující změnit bez souhlasu prodávajícího; to neplatí, žádal-li kupující opravu vady, která
  se ukáže jako neopravitelná.
</p>
<p>
  7.9. Nemá-li zboží vlastnosti stanovené v čl. 7.2 obchodních podmínek, může kupující požadovat i dodání nového zboží bez vad, pokud to není vzhledem k povaze vady nepřiměřené, ale pokud se vada týká pouze součásti zboží, může kupující
  požadovat jen výměnu součásti; není-li to možné, může odstoupit od smlouvy. Je-li to však vzhledem k povaze vady neúměrné, zejména lze-li vadu odstranit bez zbytečného odkladu, má kupující právo na bezplatné odstranění vady. Právo na
  dodání nového zboží, nebo výměnu součásti má kupující i v případě odstranitelné vady, pokud nemůže zboží řádně užívat pro opakovaný výskyt vady po opravě nebo pro větší počet vad. V takovém případě má kupující i právo od smlouvy
  odstoupit. Neodstoupí-li kupující od smlouvy nebo neuplatní-li právo na dodání nového zboží bez vad, na výměnu jeho součásti nebo na opravu zboží, může požadovat přiměřenou slevu. Kupující má právo na přiměřenou slevu i v případě, že mu
  prodávající nemůže dodat nové zboží bez vad, vyměnit jeho součást nebo zboží opravit, jakož i v případě, že prodávající nezjedná nápravu v přiměřené době nebo že by zjednání nápravy kupujícímu působilo značné obtíže.
</p>
<p>
  7.10. Další práva a povinnosti stran související s odpovědností prodávajícího za vady může upravit reklamační řád prodávajícího.
</p>
<p class="legal-pages__title--sub">8. DALŠÍ PRÁVA A POVINNOSTI SMLUVNÍCH STRAN</p>
<p>
  8.1. Kupující nabývá vlastnictví ke zboží zaplacením celé kupní ceny zboží.
</p>
<p>
  8.2. Prodávající není ve vztahu ke kupujícímu vázán žádnými kodexy chování ve smyslu ustanovení § 1826 odst. 1 písm. e) občanského zákoníku.
</p>
<p>
  8.3. Vyřizování stížností spotřebitelů zajišťuje prodávající prostřednictvím elektronické adresy&nbsp;<b>XXXXXXXX</b>. Informaci o vyřízení stížnosti kupujícího zašle prodávající na elektronickou adresu kupujícího.
</p>
<p>
  8.4. K mimosoudnímu řešení spotřebitelských sporů z kupní smlouvy je příslušná Česká obchodní inspekce, se sídlem Štěpánská 567/15, 120 00 Praha 2, IČ: 000 20 869, internetová adresa: https://adr.coi.cz/cs. Platformu pro řešení sporů
  on-line nacházející se na internetové adrese http://ec.europa.eu/consumers/odr je možné využít při řešení sporů mezi prodávajícím a kupujícím z kupní smlouvy.
</p>
<p>
  8.5. Evropské spotřebitelské centrum Česká republika, se sídlem Štěpánská 567/15, 120 00 Praha 2, internetová adresa: http://www.evropskyspotrebitel.cz je kontaktním místem podle Nařízení Evropského parlamentu a Rady (EU) č. 524/2013 ze
  dne 21. května 2013 o řešení spotřebitelských sporů on-line a o změně nařízení (ES) č. 2006/2004 a směrnice 2009/22/ES (nařízení o řešení spotřebitelských sporů on-line).
</p>
<p>
  8.6. Prodávající je oprávněn k prodeji zboží na základě živnostenského oprávnění. Živnostenskou kontrolu provádí v rámci své působnosti příslušný živnostenský úřad. Dozor nad oblastí ochrany osobních údajů vykonává Úřad pro ochranu
  osobních údajů. Česká obchodní inspekce vykonává ve vymezeném rozsahu mimo jiné dozor nad dodržováním zákona č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
</p>
<p>
  8.7. Kupující tímto přebírá na sebe nebezpečí změny okolností ve smyslu § 1765 odst. 2 občanského zákoníku.
</p>
<p class="legal-pages__title--sub">9. OCHRANA OSOBNÍCH ÚDAJŮ</p>
<p>
  9.1. Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 Nařízení Evropského parlamentu a Rady 2016/679 o ochraně fyzických osob v souvislosti se zpracováním osobních údajů a o volném pohybu těchto údajů a o zrušení směrnice
  95/46/ES (obecné nařízení o ochraně osobních údajů) (dále jen „<b>nařízení GDPR</b>“) související se zpracováním osobních údajů kupujícího pro účely plnění kupní smlouvy, pro účely jednání o kupní smlouvě a pro účely plnění
  veřejnoprávních povinností prodávajícího plní prodávající prostřednictvím zvláštního dokumentu.
</p>
<p class="legal-pages__title--sub">10. ZASÍLÁNÍ OBCHODNÍCH SDĚLENÍ A UKLÁDÁNÍ COOKIES</p>
<p>
  10.1. Kupující souhlasí ve smyslu ustanovení § 7 odst. 2 zákona č. 480/2004 Sb., o některých službách informační společnosti a o změně některých zákonů (zákon o některých službách informační společnosti), ve znění pozdějších předpisů, se
  zasíláním obchodních sdělení prodávajícím na elektronickou adresu či telefonní číslo kupujícího. Svou informační povinnost vůči kupujícímu ve smyslu čl. 13 nařízení GDPR související se zpracováním osobních údajů kupujícího pro účely
  zasílání obchodních sdělení plní prodávající prostřednictvím zvláštního dokumentu.
</p>
<p>
  10.2. Kupující souhlasí s ukládáním tzv. cookies na jeho počítač. V případě, že je nákup na webové stránce možné provést a závazky prodávajícího z kupní smlouvy plnit, aniž by docházelo k ukládání tzv. cookies na počítač kupujícího, může
  kupující souhlas podle předchozí věty kdykoliv odvolat.
</p>
<p class="legal-pages__title--sub">11. DORUČOVÁNÍ</p>
<p>
  11.1. Kupujícímu může být doručováno na elektronickou adresu kupujícího.
</p>
<p class="legal-pages__title--sub">12. ZÁVĚREČNÁ USTANOVENÍ</p>
<p>
  12.1. Pokud vztah založený kupní smlouvou obsahuje mezinárodní (zahraniční) prvek, pak strany sjednávají, že vztah se řídí českým právem. Volbou práva podle předchozí věty není kupující, který je spotřebitelem, zbaven ochrany, kterou mu
  poskytují ustanovení právního řádu, od nichž se nelze smluvně odchýlit, a jež by se v případě neexistence volby práva jinak použila dle ustanovení čl. 6 odst. 1 Nařízení Evropského parlamentu a Rady (ES) č. 593/2008 ze dne 17. června 2008
  o právu rozhodném pro smluvní závazkové vztahy (Řím I).
</p>
<p>
  12.2. Je-li některé ustanovení obchodních podmínek neplatné nebo neúčinné, nebo se takovým stane, namísto neplatných ustanovení nastoupí ustanovení, jehož smysl se neplatnému ustanovení co nejvíce přibližuje. Neplatností nebo neúčinností
  jednoho ustanovení není dotčena platnost ostatních ustanovení.
</p>
<p>
  12.3. Kupní smlouva včetně obchodních podmínek je archivována prodávajícím v elektronické podobě a není přístupná.
</p>
<p>
  12.4. Přílohu obchodních podmínek tvoří vzorový formulář pro odstoupení od kupní smlouvy.
</p>
<p>
  12.5. Kontaktní údaje prodávajícího: adresa pro doručování&nbsp;<b>XXXXXXXX</b>, adresa elektronické pošty&nbsp;<b>XXXXXXXX</b>, telefon&nbsp;<b>XXXXXXXX</b>.
</p>
<p>
  <br />
</p>
<p>V <b>XXXXXXXX</b> dne <b>XXXXXXXX</b></p>
<p>Jednatel společnosti <b>XXXXXXXX</b></p>
`
export const privacy_policy = `
<p class="legal-pages__title--main">OCHRANA OSOBNÍCH ÚDAJŮ</p>
<p>Ochrana osobních údajů kupujícího, který je fyzickou osobou (dále jen jako
   „kupující“), je poskytována v souladu s právním řádem České republiky, a to zejména s nařízením Evropského
   parlamentu a Rady (EU) č. 2016/679 ze dne 27. dubna 2016, o ochraně fyzických osob v souvislosti se
   zpracováním osobních údajů a o volném pohybu těchto údajů (dále jako „nařízení“), a s příslušnými
   vnitrostátními právními předpisy upravujícími problematiku ochrany osobních údajů.</p>
<p><b>Podmínky ochrany osobních údajů</b></p>
<p class="legal-pages__title--sub">1. Ochrana osobních údajů kupujícího</p>
<p>Vaše osobní údaje shromažďujeme a používáme výhradně v souladu s příslušnými
   právními předpisy. Těmi jsou samozřejmě vázáni i naši zaměstnanci a jiné osoby, které s námi spolupracují.
   Níže uvádíme podrobnější informace o ochraně Vašich osobních údajů. Pokud budete mít k ochraně osobních
   údajů ještě nějaké dotazy, pošlete je na<b>&nbsp;</b>e-mail<b>&nbsp;</b>.</p>
<p><b>Základní údaje o správci osobních údajů:&nbsp;</b><b>XXXXXXXX</b>,
   IČO: <b>XXXXXXXX</b> (dále také jako „prodávající“).</p>
<p class="legal-pages__title--sub">2. Rozsah, základní účel a právní titul zpracování osobních</p>
<p>Vámi poskytnuté osobní údaje zpracováváme za účelem realizace práv a povinností
   z kupní smlouvy, příp. zpracování Vašeho dotazu, a to na základě právního titulu plnění smlouvy. Jedná se
   o tyto osobní údaje: jméno a příjmení, adresa bydliště či sídla, identifikační číslo, daňové identifikační
   číslo, adresa elektronické pošty, telefonní číslo (dále jako „osobní údaje“). Poskytnutí Vašich osobních
   údajů je zcela dobrovolné, povinné osobní údaje jsou však pro uzavření kupní smlouvy nezbytné a v případě
   jejich neposkytnutí nebude moci dojít k uzavření kupní smlouvy.</p>
<p>Další účely zpracování osobních údajů jsou popsány v bodu 7. podmínek ochrany
   osobních údajů.</p>
<p class="legal-pages__title--sub">3. Způsob zpracování osobních údajů</p>
<p>Osobní údaje budou zpracovávány v elektronické podobě automatizovaným či
   neautomatizovaným způsobem nebo v tištěné podobě neautomatizovaným způsobem.</p>
<p class="legal-pages__title--sub">4. Práva subjektu údajů (kupujícího)</p>
<p>V případě jakýchkoli dotazů či připomínek ke zpracování osobních údajů je Vám k
   dispozici e-mail <b>XXXXXXXX</b>.</p>
<p class="legal-pages__title--sub">4.1. Právo na přístup k osobním údajům</p>
<p>Po dobu zpracování Vašich osobních údajů můžete po správci osobních údajů
   požadovat informace o tomto zpracování a máte také právo na jednu bezplatnou kopii Vašich osobních údajů
   zpracovávaných správcem osobních údajů.</p>
<p>Toto právo můžete uplatnit písemnou žádostí (v listinné podobě či e-mailem)
   zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu <b>XXXXXXXX</b>. V žádosti
   je nutné specifikovat svůj požadavek.</p>
<p>Na Vaši žádost budeme reagovat (vyhověním/odmítnutím) zpravidla do 1 měsíce od
   jejího obdržení.</p>
<p class="legal-pages__title--sub">4.2. Právo na opravu osobních údajů</p>
<p>Máte právo požádat o opravu neúplných či nesprávných osobních údajů, které se
   Vás týkají. Po ověření přesnosti příslušných osobních údajů Vás o vyřízení Vaší žádosti informujeme, do té
   doby bude zpracování těchto osobních údajů omezeno (nebudeme s nimi nakládat jinak než jejich
   uložením).</p>
<p>Toto právo můžete uplatnit písemnou žádostí (v listinné podobě či e-mailem)
   zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu <b>XXXXXXXX</b>;V
   žádosti je nutné specifikovat svůj požadavek.</p>
<p>Na Vaši žádost budeme reagovat (vyhověním/odmítnutím) zpravidla do 1 měsíce od
   jejího obdržení.</p>
<p class="legal-pages__title--sub">4.3. Právo na výmaz</p>
<p>Máte právo žádat o výmaz svých osobních údajů v případech, kdy máte za to, že
   Vaše osobní údaje již nemají být zpracovávány (zejm. v případech odvolání souhlasu se zpracováním osobních
   údajů, odpadnutí účelu zpracování osobních údajů, atd.).</p>
<p>Toto právo můžete uplatnit písemnou žádostí (v listinné podobě či e-mailem)
   zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu <b>XXXXXXXX</b>.
   V žádosti je nutné specifikovat svůj požadavek.</p>
<p>Na Vaši žádost budeme reagovat (vyhověním/odmítnutím) zpravidla do 1 měsíce od
   jejího obdržení.</p>
<p class="legal-pages__title--sub">4.4. Právo na omezení zpracování</p>
<p>Máte právo také požádat o omezení zpracování Vašich osobních údajů, a to
   zejména v případech, kdy máte za to, že toto zpracování probíhá v rozporu se zákonem či by mělo být na
   určitou dobu omezeno (např. posuzování žádosti o opravu osobních údajů), příp. máte zájem Vaše osobní
   údaje u nás uchovat (i v případech, kdy bychom jinak měli povinnost je vymazat) pro určení, výkon nebo
   obhajobu Vašich právních nároků.</p>
<p>Toto právo můžete uplatnit písemnou žádostí (v listinné podobě či e-mailem)
   zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu <b>XXXXXXXX</b>.V žádosti je
   nutné specifikovat svůj požadavek.</p>
<p>Na Vaši žádost budeme reagovat (vyhověním/odmítnutím) zpravidla do 1 měsíce od
   jejího obdržení.</p>
<p class="legal-pages__title--sub">4.5. Právo na přenositelnost osobních údajů</p>
<p>Vámi poskytnuté osobní údaje, které zpracováváme na základě Vašeho souhlasu či
   na základě právního titulu plnění smlouvy, mohou být na Vaši žádost přeneseny jinému správci. V případě
   vyhovění Vaší žádosti Vám, příp. pokud je to technicky možné přímo Vámi určenému jinému správci,
   poskytneme příslušné osobní údaje ve strukturovaném, běžně používaném a strojově čitelném formátu.
</p>
<p>Toto právo můžete uplatnit písemnou žádostí (v listinné podobě či e-mailem)
   zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu <b>XXXXXXXX</b>.
   V žádosti je nutné specifikovat svůj požadavek.</p>
<p>Na Vaši žádost budeme reagovat (vyhověním/odmítnutím) zpravidla do 1 měsíce od
   jejího obdržení.</p>
<p class="legal-pages__title--sub">4.6. Právo vznést námitku proti zpracování či podat stížnost u
    dozorového úřadu</p>
<p>V případech zpracování Vašich osobních údajů na základě právního titulu
   oprávněného zájmu máte právo vznést námitku proti zpracování. Toto právo můžete uplatnit písemnou žádostí
   (v listinné podobě či e-mailem) zaslanou poštou na adresu <b>XXXXXXXX</b> nebo e-mailem na adresu
   <b>XXXXXXXX</b>. V žádosti
   je nutné specifikovat svůj požadavek. Pokud se domníváte, že zpracováním Vašich osobních údajů je porušeno
   některé ustanovení nařízení (shora specifikované nařízení o ochraně osobních údajů), máte právo podat
   stížnost u dozorového úřadu. Dozorový úřad, kterému byla stížnost podána, Vás následně informuje o pokroku
   v řešení stížnosti a o jeho výsledku, jakož i o možnosti soudní ochrany.</p>
<p class="legal-pages__title--sub">5. Povinnost kupujícího uvádět osobní údaje správně a
    pravdivě</p>
<p>Aby bylo možné řádně uzavřít kupní smlouvu, máte povinnost své osobní údaje
   uvádět správně a pravdivě. V případě uvedení nesmyslných nebo nepravdivých údajů nedojde k uzavření kupní
   smlouvy.</p>
<p class="legal-pages__title--sub">6. Příjemci a další zpracovatelé osobních údajů</p>
<p>Vámi poskytnuté osobní údaje nebudou bez Vašeho předchozího souhlasu nikde
   zveřejněny ani poskytnuty třetím osobám, a to s výjimkou níže uvedených situací.</p>
<p class="legal-pages__title--sub">6.1. Příjemci osobních údajů – předání za účelem plnění
    smlouvy</p>
<p>Pokud to bude nutné ke splnění smlouvy, pověříme i jiné společnosti nebo
   jednotlivce plněním úkolů pro <b>XXXXXXXX</b>.
<p/>
<p>Výše uvedení poskytovatelé služeb obdrží pouze přístup k takovým osobním
   údajům, které jsou nutné ke splnění příslušné činnosti. Těmto poskytovatelům služeb je zakázáno používat
   Vaše osobní údaje k jiným účelům. Je-li to ze zákona nutné, budou s těmito poskytovateli služeb uzavřeny
   dohody na ochranu Vašich osobních údajů.</p>
<p class="legal-pages__title--sub">6.2. Další příjemci osobních údajů</p>
<p>V případech porušení uzavřené kupní smlouvy či vzniklých sporů z uzavřené kupní
   smlouvy můžeme zpracovávané osobní údaje poskytnout osobě (zejm. advokátní kanceláři) pověřené řešením
   příslušného sporu.</p>
<p>Vaše osobní údaje mohou být poskytnuty i auditorovi a bance za účelem řádného
   poskytování účetních a bankovních služeb, kdy Vaše osobní údaje budou adekvátně chráněny a nebudou dále
   nijak zpracovány.</p>
<p>Osobní údaje zákazníků jsme povinni poskytnout příslušným osobám také v
   případě, kdy nám tuto povinnost ukládá zákon či nařízení příslušných orgánů veřejné moci (např. z důvodu
   trestního stíhání).</p>
<p class="legal-pages__title--sub">7. Další účely zpracování osobních údajů</p>
<p class="legal-pages__title--sub">7.1. Zasílání informací a obchodních sdělení</p>
<p>Zaškrtnutím příslušného políčka v objednávce na webových stránkách nebo v
   e-mailu můžete udělit prodávajícímu souhlas ke zpracování Vašich osobních údajů za účelem zasílání
   informací a obchodních sdělení. Právním titulem k tomuto zpracování osobních údajů je Váš souhlas udělený
   výše uvedeným způsobem. Tento souhlas je zcela dobrovolný a nepodmiňuje uzavření kupní smlouvy. Lze jej
   kdykoliv odvolat, a to písemným oznámením odvolání souhlasu prodávajícímu (poštou na adresu
   <b>XXXXXXXX</b> nebo
   e-mailem na adresu <b>XXXXXXXX</b>) či prostřednictvím internetového odkazu, který je obsažen v
   rozesílce a slouží k tomuto účelu. Po odvolání Vašeho souhlasu k zasílání informací a obchodních sdělení
   nebudou Vaše osobní údaje k tomuto účelu již dále zpracovávány.</p>
<p class="legal-pages__title--sub">7.2. Cookies a další údaje sloužící pro vnitřní a statistické účely
    správce</p>
<p>Cookies jsou malé textové soubory, které prohlížeč trvale ukládá do počítače a
   které slouží k tomu, aby byla prezentace na internetu celkově uživatelsky přívětivější, efektivnější a
   bezpečnější. Cookies Váš počítač nijak nepoškodí a neobsahují žádné viry. Zabránit instalaci cookies
   můžete příslušným nastavením softwaru svého prohlížeče (např. spuštěním funkcionality anonymního
   prohlížení).</p>
<p>Naše on-line prezentace používá takové cookies. Cookies využíváme ke
   statistickým účelům. Upozorňujeme Vás na to, že pokud deaktivujete instalaci cookies, nebudete případně
   moci využívat všechny funkce naší webové stránky.</p>
<p>Váš internetový prohlížeč také při přístupu na naši webovou stránku automaticky
   odesílá údaje na náš server (např. datum a čas přístupu, URL (adresu) odkazující webové stránky, zobrazený
   soubor, množství odeslaných dat, typ prohlížeče a jeho verzi, operační systém, IP adresu). Shromažďování a
   používání nepersonalizovaných (anonymních) údajů slouží pro naše vnitřní a statistické účely.</p>
<p class="legal-pages__title--sub">8. Doba zpracování osobních údajů</p>
<p class="legal-pages__title--sub">8.1. Doba zpracování osobních údajů – účel realizace práv a povinnosti
    z kupní smlouvy</p>
<p>Osobní údaje budou pro účel realizace práv a povinností z kupní smlouvy
   zpracovávány po nezbytně nutnou dobu, tj. do dokončení realizace smlouvy, kdy tato doba zahrnuje interval
   od okamžiku objednání zboží do jeho zaplacení a uplynutí lhůty pro uplatnění práv z vad, prodloužený o 1
   rok (příp. po dobu vedení sporu). Dále budou Vaše osobní údaje pouze uloženy s ohledem na daňové a
   obchodněprávní archivační lhůty po zákonem stanovenou dobu (po dobu 10 let od konce zdaňovacího období, ve
   kterém se plnění uskutečnilo). Po uplynutí této lhůty budou vymazány. To neplatí, pokud jste udělili
   výslovný souhlas s dalším používání svých osobních údajů. Uvedenými lhůtami nejsou dotčeny jiné zákonné
   povinnosti podnikatele uchovávat příslušné dokumenty např. v rámci řádného vedení daňové evidence dle
   zákona č. 586/1992 Sb., o dani z příjmů, ve znění pozdějších předpisů a s tím související provázanosti
   účetních dokladů a záznamů dle zákona č. 563/1991 Sb., o účetnictví, ve znění pozdějších předpisů. V
   případě zahájení kontroly či řízení ze strany orgánu veřejné moci je společnost povinna příslušné
   dokumenty uchovávat také po dobu této kontroly či tohoto řízení.</p>
<p class="legal-pages__title--sub">8.2. Doba zpracování osobních údajů – účel zasílání informací a
    obchodních sdělení</p>
<p>V případě udělení souhlasu se zpracováním údajů za účelem zasílání informací a
   obchodních sdělení budou Vaše osobní údaje zpracovávány až do případného odvolání Vašeho souhlasu.
</p>
`
export const return_policy = `
<p class="legal-pages__title--main">VRACENÍ ZBOŽÍ A REKLAMACE</p>
<p>
  <b>Nemáte z dodaného zboží radost a chcete jej do 14 dnů vrátit? Nebo je poškozené?
  Nebo chcete vyměnit za jiný produkt? Je to jednoduché.</b><br /><br />
</p>

<p>
  Prvním krokem je zaslat nám zprávu za pomocí kontaktního formuláře <a href="/contact-us">ZDE</a>.
  Kam důkladně popíšete a vysvětlíte důvod Vašeho problému či rozhodnutí.
</p>

<p>
  Při výměně nebo vrácení zboží <b>je nutno vrátit nepoškozené zboží, bez známek užívání v původním nepoškozeném obalu.</b>

  Vracené zboží řádně zabalte do balíčku a odneste nebo zašlete na naši pobočku na adrese <b>XXXXXXXX</b>.
  Jakmile se zboží vrátí k nám, zkontrolujeme jej a odešleme buď peníze za vrácené
  zboží zpět na Váš účet nebo náhradní produkt na Vámi určenou adresu.
</p>
`

export default {
  terms_and_conditions,
  privacy_policy,
  return_policy
}
