import {v4 as uuid} from "uuid";

export default (languages) => ({
  minimum_basket_price: "0",
  discount_percentage: "0",
  translations: languages.reduce((tot, lang) => ({
    ...tot,
    [lang]: ""
  }), {}),
  id: uuid(),
})
