<template>
  <section class="rest-configuration scroll-content">
    <h3 class="md-display-1">{{ $translate("rest_configuration.title") }}:</h3>

    <section_description_preview>
      <div class="md-body-1">
        {{ $translate("rest_configuration.message") }}
      </div>
    </section_description_preview>

    <h3 class="md-headline">{{ $translate("rest_configuration.seo_title") }}:</h3>

    <div
      v-for="[key, val] in seo_config"
      :key="`seo-config-${key}`"
      class="rest-configuration__key"
    >
      <div class="md-title">{{ $translate(`rest_configuration.values.${key}.title`) }}:</div>
      <p class="md-body-1">{{ $translate(`rest_configuration.values.${key}.message`) }}</p>
      <md-field>
        <label>{{ $translate(`rest_configuration.values.${key}.title`) }}</label>
        <md-input
          :disabled="!can_user_manage_configuration"
          :value="val"
          @change="event => update_config(key, event.target.value, 'project')"
          md-autogrow
        />
      </md-field>
    </div>

    <br>

    <h3 class="md-headline">{{ $translate("rest_configuration.analytics_title") }}:</h3>

    <div
      v-for="[key, val] in analytics_conf"
      :key="`analytics-config-${key}`"
      class="rest-configuration__key"
    >
      <div class="md-title">{{ $translate(`rest_configuration.values.${key}.title`) }}:</div>
      <p v-if="$translate(`rest_configuration.values.${key}.message`)" class="md-body-1">{{ $translate(`rest_configuration.values.${key}.message`) }}</p>
      <md-field>
        <label>{{ $translate(`rest_configuration.values.${key}.title`) }}</label>
        <md-input
          :disabled="!can_user_manage_configuration"
          :value="val"
          @change="event => update_config(key, event.target.value, 'analytics')"
        />
      </md-field>
    </div>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { ADMIN, CONFIGURATION_STORE } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import { UPDATE_ANALYTICS_CONFIG, UPDATE_PROJECT_CONFIG } from "../../../stores/Admin/configuration/constants"
import empty_analytics_config from "../../../constants/empty_objects/empty_analytics_config"
import empty_project_config from "../../../constants/empty_objects/empty_project_config"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["analytics_config", "project_config"]),
    seo_config() {
      const config = {
        ...empty_project_config,
        ...this.project_config
      }

      return Object.entries({
        seo_description: config.seo_description,
        theme_color: config.theme_color,
        seo_keywords: config.seo_keywords
      })
    },
    analytics_conf() {
      return Object.entries({
        ...empty_analytics_config,
        ...this.analytics_config
      })
    },
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_analytics_config: UPDATE_ANALYTICS_CONFIG,
      update_project_config: UPDATE_PROJECT_CONFIG
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED,
    }),
    update_config(key, value, type) {
      this[`update_${type}_config`]([key, value])
      this.configuration_data_updated({ types_updated: ["analytics_config", "project_config"] })
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;

  .rest-configuration {
    .md-field {
      width: auto !important;
      max-width: 500px !important;
    }
  }
</style>
