export const terms_and_conditions = `
<p class="legal-pages__title--main">TERMS &amp; CONDITIONS</p>
<p>Welcome to the <b>XXXXXXXX</b> website (“the Site,” “we,” “us,” or “our”). <b>XXXXXXXX </b>providers this site as a service to its customers.
Please read the following terms of service (“Terms”) as they govern your use of our site. By using this Site, you agree
to follow and be bound by these Terms as well as any other terms, guidelines or rules that apply to any portion of this site, without limitation or qualification.
If you do not agree to these Terms, please do not use this Site in any way.</p>

<p>Additionally, <b>XXXXXXXX </b>reserves the right to modify these Terms at any time without prior notification. Site
users must agree upon and abide by these changes accordingly. Please review this page periodically for changes. Any use
of our Site at any time constitutes full acceptance of our service Terms.</p>

<p class="legal-pages__title--sub">Privacy</p>
<p>We have adopted a Privacy Policy that you should refer to in order to fully understand how we collect and use your
information.</p>

<p class="legal-pages__title--sub">Usage Restrictions</p>
<p>All of the content that appears on the <b>XXXXXXXX</b> websites, including all visuals, text, audio and video clips
are subject to copyright protections and /or other intellectual property rights or licenses held by <b>XXXXXXXX</b>.
The entire content of the <b>XXXXXXXX </b>websites is copyrighted as a collective work under U.S. copyright laws.
<b>XXXXXXXX</b> grants you a limited license to access and make personal use of the content on this website. Content
of the <b>XXXXXXXX</b> websites is intended solely for the personal, noncommercial use by the users of our Site. Any
unauthorized use of any content or materials on this Site is strictly prohibited and may violate copyright and trademark
laws, and/or the laws of privacy, publicity, and/or communications regulations and statutes. You may use the materials
or content on this Site only with our prior written and express authorization. To inquire about obtaining authorization
to use the materials or content on this Site, please contact us <a href="/contact-us">HERE</a>.</p>

<p class="legal-pages__title--sub">Trademarks</p>
<p>
All trademarks, service marks, and trade names (collectively the "Marks") that appear on this Site are proprietary to
<b>XXXXXXXX</b>, or the other respective owners of such Marks. You may not display or reproduce the Marks in any
manner without the prior written consent of <b>XXXXXXXX</b>, and you may not remove or otherwise modify in any
manner any trademark notices from any content offered or received through the Site.</p>

<p class="legal-pages__title--sub">Limited License and Site Access</p><p>
We grant you a limited license to use the Site for personal use only. Consequently, this grant does not allow you to do
any of the following: (a) resell or make any commercial use of this Site or any of the contents of this Site; (b)
modify, adapt, translate, reverse engineer, decompile, disassemble or convert into human readable form any of the
contents of this Site not intended to be so read. This includes using or directly viewing the underlying HTML or other
code from this Site except as interpreted and displayed in a web browser; (c) copy, imitate, mirror, reproduce,
distribute, publish, download, display, perform, post or transmit any of the contents of this Site (including any Marks)
in any form or by any means, including, but not limited to, electronic, mechanical, photocopying, recording or
otherwise; or (d) use any data mining, bots, spiders, automated tools or similar data gathering and extraction methods
on the contents of the Site or to collect any information from the Site or any other user of the Site.</p>

<p class="legal-pages__title--sub">Membership Eligibility</p>
<p>
Membership on <b>XXXXXXXX</b> websites is free. However, our membership services are not available to users under
the age of 18 or to members who, at our discretion, have been suspended or removed from the <b>XXXXXXXX</b> systems. Members agree to all terms of service and must only apply for membership if eligible.</p>

<p class="legal-pages__title--sub">Notice</p>
<p><b>XXXXXXXX</b> may contact you or provide you with service-related and/ or promotional notices by means of postal
mail, electronic mail, general site notifications and more using the contact information you have provided to us.</p>

<p class="legal-pages__title--sub">Pricing Policy</p>

<p>At <b>XXXXXXXX</b>, we are committed to providing our customers with great quality products. Our products are sold
online only at limited quantities and availabilities. We have done our best to display our items as accurately as
possible on the Site. Please be aware, variations in style, color, size, shape and look may occur. If you are not
satisfied your purchase, please review our Returns &amp; Exchanges section for further assistance.</p>

<p class="legal-pages__title--sub">Site Use</p>
<p>
You cannot access or use the <b>XXXXXXXX</b> websites for any illegal or unauthorized purpose. Harassment in any
manner or form of the Site, including via e-mail and chat or by obscene or abusive language, is strictly forbidden.
Although <b>XXXXXXXX</b> cannot monitor the conduct of its users, it is a strict violation of these Terms to use any
information obtained from our Site in order to harass, abuse or harm another person, or in order to contact, advertise,
solicit or sell to any user without his/her prior explicit consent.

Also, you agree that no comments or other user submissions submitted by you to the <b>XXXXXXXX</b> websites will violate any right of any third party, including copyright, trademark, privacy or other personal rights. You further
agree that no comments submitted by you to the Site will be or contain libelous or otherwise unlawful, abusive or
obscene material. You are and shall remain solely responsible for the content of any comments you make.</p>

<p class="legal-pages__title--sub">User Submissions</p>
<p><b>XXXXXXXX</b> strives to create an interactive relationship with the members of its Site and services. User
comments, feedback, suggestions, ideas, and other submissions disclosed, submitted or offered to <b>XXXXXXXX</b> on or by this Site shall be and remain <b>XXXXXXXX</b> property. Users assign all property rights and interests to
<b>XXXXXXXX</b> allowing our company unlimited use, commercial or otherwise, of any comments. Furthermore, <b>XXXXXXXX</b>shall be under no obligation (1) to maintain any comments in confidence; (2) to pay to users any compensation
for any comments; or (3) to respond to any user comments.</p>

<p class="legal-pages__title--sub">Monitoring</p>
<p><b>XXXXXXXX</b> has no obligation to monitor this Site or any portion thereof. However, we reserve the right to
review any posted content and remove, delete, redact or otherwise modify such content, in our sole discretion, at any
time and from time to time, without notice or further obligation to you. <b>XXXXXXXX</b> has no obligation to
display or post any content. <b>XXXXXXXX</b>, subject to the Privacy Policy referenced above, reserves the right to
disclose, at any time and from time to time, any information or posted content that it deems necessary or appropriate,
including without limitation to satisfy any applicable, law, regulation, contractual obligation, legal, dispute process,
or governmental request. <b>XXXXXXXX</b> shall have no liability in connection with any content or content submitted
to, transmitted via, or displayed or posted on this Site, regardless of whether provided by <b>XXXXXXXX</b> or any other party.
</p>

<p class="legal-pages__title--sub">Correction of Site Errors</p>
<p>
The information on the <b>XXXXXXXX</b> websites may contain typographical errors or inaccuracies. We reserve the
right to update any information we see fit at any time without prior notice. Please note that such errors may relate to
product information, pricing and availability. In these instances, we shall have the right to terminate any orders
involving pricing errors or inaccuracies. We apologize for any inconveniences.

</p>

<p class="legal-pages__title--sub">Modification/Termination of Website</p>
<p>
In the event of termination, you will still be bound by your obligations under these terms of service, including the
warranties made by you, and by the disclaimers and limitations of liability. <b>XXXXXXXX</b> shall not be liable to you or any third-party for any termination of your access.</p>

<p class="legal-pages__title--sub">Third Party Links</p>
<p>
To improve our Site, <b>XXXXXXXX</b> may use and promote services provided by outside third parties. However, even
if the third party is affiliated with <b>XXXXXXXX</b>, we do not control these services and make no representations
regarding these persons or entities. We are not liable or responsible for the accuracy, completeness, timeliness,
reliability or availability of, any such parties. Outside sites linked on our Site, all of which have separate privacy
and data collection practices, are only for your convenience and therefore you access them at your own risk.
</p>

<p class="legal-pages__title--sub">Termination<p>
<p>
Notwithstanding any of these Terms of Use, we reserve the right, without notice or attendant liability, and in our sole
discretion, to terminate your license to use this Site, and to block or prevent future access to and use of this Site
for any reason or no reason. Upon termination, these Terms of Use will still apply.
</p>

<p>
WE ARE NOT RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS A RESULT OF ANY DEALINGS WITH ANY THIRD PARTY WEBSITE OR MERCHANT OR OPERATOR OF SUCH A THIRD PARTY WEBSITE.

DISCLAIMER

ALL CONTENT CONTAINED ON THIS SITE, INCLUDING TEXT, GRAPHICS, AND LINKS, ARE PROVIDED ON AN "AS IS" BASIS WITH NO
WARRANTY. TO THE MAXIMUM EXTENT PERMITTED BY LAW, <b>XXXXXXXX </b>DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES,
EXPRESS OR IMPLIED, WITH RESPECT TO SUCH CONTENT, WHETHER PROVIDED OR OWNED BY <b>XXXXXXXX</b> OR BY ANY THIRD PARTY, INCLUDING BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, FREEDOM FROM COMPUTER VIRUS, AND IMPLIED WARRANTIES ARISING FROM COURSE OF DEALING OR COURSE OF PERFORMANCE. IN ADDITION, <b style="color: var(--md-theme-default-text-primary-on-background, rgba(0,0,0,0.87)); letter-spacing: 0.01em;">XXXXXXXX</b> DOES NOT REPRESENT OR WARRANT THAT THE CONTENT ACCESSIBLE VIA THIS SITE IS ACCURATE, COMPLETE, OR CURRENT, AND YOU MAY NOT RELY ON THE CONTENT ACCESSIBLE VIA THIS SITE.

LIMITATION OF LIABILITY

TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL <b>XXXXXXXX</b>, ITS OFFICERS, DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES WHATSOEVER RESULTING FROM ANY: (I) ERRORS, MISTAKES, OR INACCURACIES WITH REGARD TO THE CONTENT CONTAINED ON THIS SITE; (II) ANY UNAUTHORIZED ACCESS TO OR USE OF THE SITE OR <b>XXXXXXXX</b>’S SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN; (III) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SITE; (IV) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH OUR SITE BY ANY THIRD PARTY; OR (V) ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED BY YOU AS A RESULT OF YOUR USE OF OR RELIANCE ON THE CONTENT CONTAINED ON THIS SITE, WHETHER BASED ON TORT, STRICT LIABILITY, BREACH OF CONTRACT, BREACH OF WARRANTY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT <b>XXXXXXXX </b>WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.

INDEMNIFICATION

YOU AGREE TO INDEMNIFY, DEFEND, AND HOLD HARMLESS <b>XXXXXXXX</b>, ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS,
SUPPLIERS AND THIRD PARTY PARTNERS FROM AND AGAINST ALL LOSSES, EXPENSES, DAMAGES AND COSTS, INCLUDING REASONABLE
ATTORNEYS' FEES, RESULTING FROM: (1) YOUR USE OF THE SITE, INCLUDING WITHOUT LIMITATION, ANY CONTENT SUBMITTED OR
TRANSMITTED BY YOU VIA THE SITE; (2) ANY USE OF ANY PASSWORD CHOSEN BY OR ISSUED TO YOU; OR (3) ANY VIOLATION BY YOU OF:
(A) THESE TERMS OF USE; (B) <b>XXXXXXXX</b>'S PRIVACY POLICY; (C) ANY OTHER TERMS, GUIDELINES OR RULES APPLICABLE TO
THE SITE; (D) ANY RIGHTS OF ANY OTHER PERSON OR ENTITY; OR (E) ANY APPLICABLE LAWS, RULES, OR REGULATIONS.

Entire Agreement

These Terms of Use constitute the entire agreement between the user and <b>XXXXXXXX</b> with respect to this Site
and supersede all prior or contemporaneous communications and proposals, whether electronic, oral or written with
respect to this Site. A printed version of these Terms of Use and of any notice given in electronic form shall be
admissible in judicial or administrative proceedings based upon or relating to these Terms of Use to the same extent and
subject to the same conditions as other business documents and records originally generated and maintained in printed
form. Any rights not expressly granted herein are reserved.
</p>
`
export const privacy_policy = `
<p class="legal-pages__title--main">PRIVACY POLICY</p>

<p>This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from <b>XXXXXXXX</b> (the “Site”).</p>

<p class="legal-pages__title--sub">PERSONAL INFORMATION WE COLLECT</p>

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”

We collect Device Information using the following technologies:

    - “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
    - “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
    - “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
    - Hotjar tracking.

Additionally when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information
(including credit card numbers), email address, and phone number.  We refer to this information as “Order Information.”

When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.

<p class="legal-pages__title--sub">HOW DO WE USE YOUR PERSONAL INFORMATION?</p>

We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations).
Additionally, we use this Order Information to:

Communicate with you;
Screen our orders for potential risk or fraud; and
When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.
We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site
(for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).

<p class="legal-pages__title--sub">SHARING YOUR PERSONAL INFORMATION</p>

We share your Personal Information with third parties to help us use your Personal Information, as described above.  For example, we use Shopify to power our online store--you can read more about how Shopify uses your Personal Information here: https://www.shopify.com/legal/privacy.
We also use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here:  https://www.google.com/intl/en/policies/privacy/.
We also use Hotjar to help us understand how our customers use the Site.

Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.

<p class="legal-pages__title--sub">BEHAVIOURAL ADVERTISING</p>
As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.  For more information about how targeted advertising works,
you can visit the Network Advertising Initiative’s (“NAI”) educational page at http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.

You can opt out of targeted advertising by:
  FACEBOOK - https://www.facebook.com/settings/?tab=ads
  GOOGLE - https://www.google.com/settings/ads/anonymous
  BING - https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads

Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s opt-out portal at:  http://optout.aboutads.info/.

<p class="legal-pages__title--sub">DO NOT TRACK</p>
Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.

<p class="legal-pages__title--sub">YOUR RIGHTS</p>
If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted.
If you would like to exercise this right, please contact us through the contact information below.

Additionally, if you are a European resident we note that we are processing your information in order to fulfill contracts we might have with you (for example if you make an order through the Site),
or otherwise to pursue our legitimate business interests listed above.  Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.

<p class="legal-pages__title--sub">DATA RETENTION</p>
When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.

<p class="legal-pages__title--sub">CHANGES</p>
We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.

<p class="legal-pages__title--sub">CONTACT US</p>
<p>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at <b>XXXXXXXX</b> or by mail using the details provided below:

<b>XXXXXXXX</b></p>
`
export const return_policy = `
<p class="legal-pages__title--main">RETURNS</p>

Our policy lasts 30 days. If 30 days have gone by since your purchase, unfortunately we can’t offer you a refund or exchange.

To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.

Several types of goods are exempt from being returned. Perishable goods such as food, flowers, newspapers or magazines cannot be returned.
We also do not accept products that are intimate or sanitary goods, hazardous materials, or flammable liquids or gases.

Additional non-returnable items:
* Gift cards
* Downloadable software products
* Some health and personal care items

To complete your return, we require a receipt or proof of purchase.

<p class="legal-pages__message--sub>Please do not send your purchase back to the manufacturer.</p>

There are certain situations where only partial refunds are granted: (if applicable)
* Book with obvious signs of use
* CD, DVD, VHS tape, software, video game, cassette tape, or vinyl record that has been opened.
* Any item not in its original condition, is damaged or missing parts for reasons not due to our error.
* Any item that is returned more than 30 days after delivery

<p class="legal-pages__title--sub">Refunds (if applicable)</p>
Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.
If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.

<p class="legal-pages__title--sub">Late or missing refunds (if applicable)</p>
<p>If you haven’t received a refund yet, first check your bank account again.
Then contact your credit card company, it may take some time before your refund is officially posted.
Next contact your bank. There is often some processing time before a refund is posted.
If you’ve done all of this and you still have not received your refund yet, please contact us at <b>XXXXXXXX</b>.</p>

<p class="legal-pages__title--sub">Sale items (if applicable)</p>
Only regular priced items may be refunded, unfortunately sale items cannot be refunded.

<p class="legal-pages__title--sub">Exchanges (if applicable)</p>
<p>We only replace items if they are defective or damaged.  If you need to exchange it for the same item, send us an email at <b>XXXXXXXX</b> and send/bring your item to: <b>XXXXXXXX</b>.</p>

<p class="legal-pages__title--sub">Gifts</p>
If the item was marked as a gift when purchased and shipped directly to you, you’ll receive a gift credit for the value of your return. Once the returned item is received, a gift certificate will be mailed to you.

If the item wasn’t marked as a gift when purchased, or the gift giver had the order shipped to themselves to give to you later, we will send a refund to the gift giver and he will find out about your return.

<p class="legal-pages__title--sub">Shipping</p>
<p>To return your product, you should mail/bring your product to: <b>XXXXXXXX</b>.</p>

You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.

Depending on where you live, the time it may take for your exchanged product to reach you, may vary.

If you are shipping an item over $75, you should consider using a trackable shipping service or purchasing shipping insurance. We don’t guarantee that we will receive your returned item.
</p>`

export default {
  terms_and_conditions,
  privacy_policy,
  return_policy
}
