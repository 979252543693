<template>
  <section class="translations-editor scroll-content">
    <h3 class="md-display-1">{{ $translate("translations_editor.title") }}:</h3>
    <p class="md-body-1 translations-editor__top-messages">{{ $translate("translations_editor.message") }}</p>

    <hr>

    <language_switcher
      v-if="can_user_manage_configuration"
      :selected_language="selected_language"
      :show_title="false"
      @change-language="lang => switch_language(lang)"
    />

    <hr>

    <div class="flag-changer">
      <img
        :src="correct_flag_image"
        :alt="`${selected_language} flag`"
        :class="{ 'flag-changer__empty-language': !translations[selected_language]}"
      >
      <span
        class="flag-changer__active-state"
        :class="{ 'flag-changer__active-state--active': translations[selected_language]}"
      >
        <md-tooltip md-direction="top">{{ $translate( translations[selected_language] ? "language_active" : "language_inactive") }}</md-tooltip>
      </span>
      <md-button
        data-cy="activate-language"
        v-if="can_user_manage_configuration"
        class="flag-changer__remove"
        :class="{ 'md-accent': translations[selected_language], 'md-primary': !translations[selected_language] }"
        :disabled="translations[selected_language] && !can_user_remove_language"
        @click="translations[selected_language] ? deactivate_language() : activate_language()"
      >
        <md-tooltip v-if="translations[selected_language] && !can_user_remove_language" md-direction="top">{{ $translate("translations_editor.remove_language_message") }}</md-tooltip>
        {{ translations[selected_language] ? $translate("translations_editor.deactivate_language") : $translate("translations_editor.activate_language") }}
      </md-button>
    </div>

    <div v-if="translations[selected_language]">
      <div class="md-layout md-gutter">
        <div class="md-layout-item md-xsmall-size-33 md-small-size-33 md-size-25">
          <md-field data-cy="currency-input">
            <label>{{ $translate("translations_editor.currency") }}</label>
            <md-select :disabled="!can_user_manage_configuration" v-model="currency_code" id="currency-input">
              <md-option
                v-for="currency in allowed_currencies"
                :key="currency"
                :value="currency"
              >{{ currency }}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-33 md-small-size-33 md-size-25">
          <md-field data-cy="currency-sign">
            <label>{{ $translate("translations_editor.currency_sign") }}</label>
            <md-select :disabled="!can_user_manage_configuration" v-model="currency_symbol" id="currency-sign">
              <md-option
                v-for="(currency_symbol, index) in allowed_currency_signs"
                :key="index"
                :value="currency_symbol"
              >{{ currency_symbol }}</md-option>
            </md-select>
          </md-field>
        </div>
        <div class="md-layout-item md-xsmall-size-33 md-small-size-33 md-size-15">
          <md-field>
            <label>{{ $translate("translations_editor.tax_percantage") }}</label>
            <md-input
              data-cy="tax-percentage"
              type="number"
              :disabled="!can_user_manage_configuration"
              :value="tax_percantage"
              @change="event => tax_percantage = event.target.value"
            />
            <md-tooltip md-direction="bottom">{{ $translate("translations_editor.tax_percantage_message") }}</md-tooltip>
          </md-field>
        </div>
      </div>

      <md-table v-if="show_translations" v-model="selected_translations" md-fixed-header class="translations-editor__inputs admin-table">
        <md-table-row slot="md-table-row" slot-scope="{ item }">
          <md-table-cell v-if="!['tax_percantage', 'currency_symbol', 'currency_code'].includes(item.key)" :md-label="$translate('translations_editor.translations_key')">{{ item.key }}</md-table-cell>
          <md-table-cell v-if="!['tax_percantage', 'currency_symbol', 'currency_code'].includes(item.key)" :md-label="$translate('translations_editor.value')">
            <md-field v-if="typeof item.val === 'string' || typeof item.val === 'number'">
              <md-input
                :type="typeof item.val === 'string' ? 'text' : 'number'"
                :disabled="!can_user_manage_configuration"
                :value="item.val || ''"
                @change="event => update_translations(item.key, event.target.value)"
                :placeholder="replace_underscores_for_spaces(item.key)"
              />
            </md-field>
            <md-table v-else v-model="item.val">
              <md-table-row slot="md-table-row" slot-scope="{ item: sub_item }">
                <md-table-cell :md-label="$translate('translations_editor.translations_key')">{{ sub_item.key }}</md-table-cell>
                <md-table-cell :md-label="$translate('translations_editor.value')">
                  <md-field>
                    <md-input
                      :disabled="!can_user_manage_configuration"
                      :value="sub_item.val || ''"
                      @change="event => update_translations(item.key, event.target.value, sub_item.key)"
                      :placeholder="replace_underscores_for_spaces(sub_item.key)"
                    />
                  </md-field>
                </md-table-cell>
              </md-table-row>
            </md-table>
          </md-table-cell>
        </md-table-row>
      </md-table>
    </div>
    <h3 v-else class="md-title">{{ $translate("translations_editor.activation_message") }}</h3>
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { ADMIN, CONFIGURATION_STORE, translation_objects } from "../../../constants/others_constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import replace_underscores_for_spaces from "../../../../Shared/methods/replace_underscores_for_spaces"
import {
  UPDATE_TRANSLATIONS,
  REMOVE_TRANSLATION,
} from "../../../stores/Admin/configuration/constants"
import default_translations_object from "../../../constants/empty_objects/default_translations_object"
import default_legal_translations_object_cz from "../../../constants/empty_objects/default_legal_translations_object_cz"
import default_legal_translations_object_en from "../../../constants/empty_objects/default_legal_translations_object_en"
import { languages, default_language, allowed_currencies, allowed_currency_signs } from "../../../../Shared/constants/other"
import language_switcher from "../language_switcher"

export default {
  components: {
    language_switcher
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      show_translations: true,
      switching_language: false,
      number_of_loaded_editors: 0,
      selected_language: "",
      default_legal_page_translations: {
        en: default_legal_translations_object_en,
        cz: default_legal_translations_object_cz
      },
      languages,
      allowed_currencies,
      allowed_currency_signs,
      default_language
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, ["translations"]),
    selected_translations() {
      return Object
        .entries(this.translations[this.selected_language] || translation_objects[this.selected_language] || default_translations_object)
        .flatMap(([key, val]) => ({
          key,
          val: (typeof val === "string" || typeof val === "number") ?
            val : Object.entries(val).map(([sub_key, sub_val]) => ({ key: sub_key, val: sub_val }))
        }))
    },
    correct_flag_image() {
      return `/static/icons/countries/${this.selected_language}.svg?${this.selected_language}`
    },
    can_user_remove_language() {
      return Object.keys(this.translations).length > 1
    },
    currency_code: {
      get() { return this.translations[this.selected_language].currency_code },
      set(value) {
        this.update_translations("currency_code", value)
      }
    },
    currency_symbol: {
      get() { return this.translations[this.selected_language].currency_symbol },
      set(value) {
        this.update_translations("currency_symbol", value)
      }
    },
    tax_percantage: {
      get() { return this.translations[this.selected_language].tax_percantage || "" },
      set(value) {
        this.update_translations("tax_percantage", value)
      }
    }
  },
  watch: {
    can_user_remove_language() {
      this.show_translations = false
      this.$nextTick(() => this.show_translations = true) // Needed for element re-render and flag change
    }
  },
  created() {
    this.selected_language = (Object.keys(this.translations) || [])[0] || default_language
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_translations_content: UPDATE_TRANSLATIONS,
      remove_translation: REMOVE_TRANSLATION,
    }),
    ...mapMutations(ADMIN, {
      configuration_data_updated: CONFIGURATION_DATA_UPDATED
    }),
    replace_underscores_for_spaces,
    activate_language() {
      this.show_translations = false
      this.$nextTick(() => this.show_translations = true) // Needed for element re-render and flag change
      this.update_translations_content([null, null, this.selected_language, null, true])
      this.configuration_data_updated({ types_updated: ["translations"] })
    },
    deactivate_language() {
      return this.can_user_remove_language && this.remove_selected_translation()
    },
    switch_language(language) {
      /**
       * Because switching language for legal pages took very long
       * this ensures a faster switch
       */
      this.selected_language = language
      this.switching_language = true

      this.$nextTick(() => {
        this.switching_language = false
      })
    },
    update_translations(key, value, sub_key) {
      if (this.switching_language) return

      this.update_translations_content([key, value, this.selected_language, sub_key])
      this.configuration_data_updated({ types_updated: ["translations"] })
    },
    remove_selected_translation() {
      this.remove_translation(this.selected_language)
      this.configuration_data_updated({ types_updated: ["translations"] })
    }
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/admin" as *;
  @use "../../../../../styles/_global-constants" as *;
  @use "../../../../../styles/flag_change.scss";

  .translations-editor {
    img {
      width: 50px;
      cursor: pointer;
    }

    &__other-pages {
      margin-top: $default-size;
    }

    &__buttons {
      float: right;

      @media (max-width: $tablet--small) {
        float: unset;
      }
    }

    &__top-messages {
      width: fit-content;
      padding: $half-default-size;
      background-color: $material-orange;
      border-radius: $border-radius;
      color: $pure-black;
    }

    &__empty-language {
      opacity: 0.5;
      filter: none !important;
      cursor: initial !important;
    }

    .md-table {
      width: 100%;
      padding: 0;

      &-toolbar {
        display: block;
        line-height: 64px;
      }
    }

    .md-layout.md-gutter {
      width: 100%;
    }

    .md-body-1 {
      span {
        color: $blue;
        cursor: pointer;
      }
    }

    &__currency {
      max-width: 200px;
    }

    &__inputs {
      .md-field {
        width: auto;
        min-width: 200px;
        margin: 0;
        min-height: 30px;
        padding: 0;
      }

      img {
        display: inline-block;
        vertical-align: middle;
        filter: drop-shadow(0 0 10px rgba($pure-black,0.5));
      }

      &--block {
        display: block !important;
      }

      .md-table-content {
        height: auto !important;
        max-height: unset !important;
        padding: 0;
      }
    }

    svg {
      max-width: 400px;
      max-height: 400px;
    }
  }
</style>
