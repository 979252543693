import {v4 as uuid} from "uuid";

export default (languages) => ({
  code: "",
  price: "0",
  discounted_price: "0",
  source: "",
  translations: languages.reduce((tot, lang) => ({
    ...tot,
    [lang]: {
      price: "0"
    }
  }), {}),
  carrier_identifier: "",
  requested_fulfillment_service_id: "",
  id: uuid(),
  allowed_countries: [],
  estimated_days: undefined,
})
