<template>
  <section class="edit-page-type-translations scroll-content">
    <h3 class="md-display-1">{{ $translate("edit_page_type_translations.title") }}:</h3>

    <section_description_preview :dense="true">
      <div class="md-body-1">{{ $translate("edit_page_type_translations.message") }}</div>
    </section_description_preview>

    <div class="edit-page-type-translations__single">
      <p class="md-title">{{ $translate("edit_page_type_translations.single_product_title") }}</p>

      <div class="md-layout md-gutter">
        <page_type_translations_editor
          v-for="[page_id, translations] in single_product_pages"
          :key="page_id"
          :page_id="page_id"
          :page_translations="translations"
          :can_user_manage_configuration="can_user_manage_configuration"
          is_single_product_page
          class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100"
        />
      </div>

      <br>

      <md-button v-if="can_user_manage_configuration" class="md-icon-button md-fab md-primary" @click="() => toggle_modal(true)">
        <md-icon>add</md-icon>
        <md-tooltip md-direction="top">
          {{ $translate("edit_page_type_translations.add_type_page", { type: $translate("edit_page_type_translations.single_product") }) }}
        </md-tooltip>
      </md-button>
    </div>
    <div class="edit-page-type-translations__couple">
      <p class="md-title">{{ $translate("edit_page_type_translations.couple_product_title") }}</p>
      
      <div class="md-layout md-gutter">
        <page_type_translations_editor
          v-for="[page_id, translations] in couple_product_pages"
          :key="page_id"
          :page_id="page_id"
          :page_translations="translations"
          :can_user_manage_configuration="can_user_manage_configuration"
          :is_single_product_page="false"
          class="md-layout-item md-size-33 md-small-size-50 md-xsmall-size-100"
        />
      </div>

      <br>

      <md-button v-if="can_user_manage_configuration" class="md-icon-button md-fab md-primary" @click="() => toggle_modal(false)">
        <md-icon>add</md-icon>
        <md-tooltip md-direction="top">
          {{ $translate("edit_page_type_translations.add_type_page", { type: $translate("edit_page_type_translations.couple_product") }) }}
        </md-tooltip>
      </md-button>
    </div>

    <entity_selection_modal
      :modal_title="$translate(
        `edit_page_type_translations.modal_title`,
        { entity: $translate(`edit_page_type_translations.entity_type.${selecting_product ? 'product' : 'collection'}`) }
      )"
      :enable_direct_link_selection="false"
      :open_modal="open_products_modal"
      :enable_collection_selection="!selecting_product"
      :enable_product_selection="selecting_product"
      :send_full_data="!selecting_product"
      @entity_selected="entity_selected"
      @toggle_modal="toggle_modal"
    />
  </section>
</template>

<script>
import { mapState, mapMutations } from "vuex"
import { CONFIGURATION_STORE, ADMIN, PRODUCTS_STORE, shopify, imported } from "../../../constants/others_constants"
import { UPDATE_PAGE_TYPE_TRANSLATION, DELETE_PAGE_TYPE_TRANSLATION } from "../../../stores/Admin/configuration/constants"
import { CONFIGURATION_DATA_UPDATED } from "../../../stores/Admin/constants"
import entity_selection_modal from "../entity_selection_modal"
import { page_types } from "../../../../../data/other_constants"
import page_type_translations_editor from "./page_type_translations_editor"
import section_description_preview from "../section_description_preview"

export default {
  components: {
    section_description_preview,
    entity_selection_modal,
    page_type_translations_editor
  },
  data() {
    return {
      open_products_modal: false,
      selecting_product: true,
      page_types
    }
  },
  props: {
    can_user_manage_configuration: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapState(CONFIGURATION_STORE, [
      page_types.single_product_homepages,
      page_types.couple_products_homepages,
      "translations",
      "shopify_config"
    ]),
    ...mapState(PRODUCTS_STORE, [
      "imported_collections",
      "imported_products",
      "shopify_collections",
      "shopify_products"
    ]),
    products_type() {
      return this.shopify_config.should_use_shopify_data ? shopify : imported
    },
    single_product_pages() {
      if (this.single_product_homepages) return Object
        .entries(this.single_product_homepages)
      
      return []
    },
    couple_product_pages() {
      if (this.couple_products_homepages) return Object
        .entries(this.couple_products_homepages)

      return []
    },
    get_all_products() {
      return this[`${this.products_type}_products`] || []
    },
    get_all_collections() {
      return this[`${this.products_type}_collections`] || []
    },
  },
  methods: {
    ...mapMutations(CONFIGURATION_STORE, {
      update_page_type: UPDATE_PAGE_TYPE_TRANSLATION,
      delete_page_type: DELETE_PAGE_TYPE_TRANSLATION,
    }),
    ...mapMutations(ADMIN, {
      configuration_data_update: CONFIGURATION_DATA_UPDATED,
    }),
    entity_selected(product_id_or_collection) {
      const correct_entity = this.get_all_products.find(
        ({ id }) => id === product_id_or_collection
      ) || product_id_or_collection

      this.update_page_type([
        this.selecting_product ? page_types.single_product_homepages : page_types.couple_products_homepages,
        correct_entity.buffer_id || correct_entity.id || product_id_or_collection,
        null,
        null,
        this.selecting_product ? this.selected_language_single : this.selected_language_couple
      ])
      this.configuration_data_update({
        types_updated: [this.selecting_product ? page_types.single_product_homepages : page_types.couple_products_homepages]
      })
      this.toggle_modal()
    },
    toggle_modal(is_product) {
      this.selecting_product = is_product
      this.open_products_modal = !this.open_products_modal
    },
  }
}
</script>

<style lang="scss">
  @use "../../../../../styles/_admin-constants" as *;
  @use "../../../../../styles/_global-constants" as *;

  .edit-page-type-translations {
    $flags-height: 50px;

    img {
      display: inline-block;
      width: 35px;
      cursor: pointer;
      object-fit: contain;
    }

    .md-card {
      .md-card-content {
        padding-top: 0;

        img {
          filter: drop-shadow(0 0 10px rgba($pure-black,0.5));
        }
      }

      .md-card-actions {
        height: $flags-height;
      }

      .md-card-header {
        display: grid;
        grid-template-columns: 1fr #{$icon-size * 2 + $half-default-size};
        padding: $half-default-size;
        align-items: center;

        .md-title {
          margin: 0 !important;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;

          @media (max-width: $mobile--large) {
            font-size: $font-size--medium-large;
            line-height: normal;

            .md-icon {
              width: $font-size--medium-large;
              min-width: $font-size--medium-large;
              height: $font-size--medium-large;
              font-size: $font-size--medium-large!important;
            }
          }
        }
      }
    }

    &__card {
      margin-bottom: $default-size;
    }

    &__actions {
      text-align: right;
    }

    &__active-state {
      display: inline-block;
      width: 10px;
      height: 10px;
      margin-left: $half-default-size;
      border-radius: 100%;
      background-color: $red-vivid;
      vertical-align: middle;

      &--active {
        background-color: $green-vivid;
      }
    }

    &__empty-language {
      opacity: 0.5;
      filter: none !important;
      cursor: initial !important;
    }

    &__flags {
      position: relative;
      display: inline-block;
      max-width: calc(100% - #{$flags-height});
      overflow: auto;
      vertical-align: middle;

      &--wrapper {
        display: flex;
        padding-right: $default-size;
      }
    }

    &__single {
      margin-bottom: calc(3 * #{$default-size});
    }

    &__translation {
      display: block;
      margin-bottom: $default-size;

      span, .md-field {
        display: inline-block;
        box-sizing: border-box;
        vertical-align: middle;
      }

      span {
        width: calc(40% - 15px);
        margin-right: 10px;
        word-break: break-word;
      }

      .md-field {
        width: 60%;
        min-height: auto;
        padding: 0;
        margin: 0;
      }
    }
  }
</style>
